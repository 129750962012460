import { useRef, useState } from 'react'
import { Paper, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { InputV2 } from '../../../../../components/InputV2'
import useOnClickOutside from '../../../../../hooks/useOnClickOutside'

interface Props {
  options: string[]
  onClick: (option: string) => void
  onClose: () => void
}

export default function TextInputAutocomplete(props: Props) {
  const { options, onClick, onClose } = props

  const [searchTerm, setSearchTerm] = useState('')

  const filteredVariables = options.filter((variable) =>
    variable.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const containerRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(containerRef, onClose)

  return (
    <Paper
      ref={containerRef}
      elevation={3}
      sx={{
        position: 'absolute',
        width: '100%',
        maxHeight: 300,
        overflowY: 'auto',
        zIndex: 10000,
        top: '100%',
        left: 0,
        mt: 1,
        p: 1,
        borderRadius: 2,
      }}
    >
      <div
        style={{
          position: 'sticky',
          top: 0,
          background: 'white',
          zIndex: 10,
          paddingBottom: '8px',
        }}
      >
        <InputV2 
          placeholder="Buscar"
          formik={false}
          value={searchTerm}
          onChange={(e: any) => setSearchTerm(e.target.value)}
        />
      </div>

      <List dense>
        {filteredVariables.map((variable) => (
          <ListItem disablePadding key={variable} sx={{ minHeight: '32px' }}>
            <ListItemButton onClick={() => onClick(variable)} sx={{ py: 0.5 }}>
              <ListItemText primary={variable} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Paper>
  )
}
