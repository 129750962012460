import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useToast } from '../../../hooks/useToast'

import { IInvoiceEditData } from '../../../services/requests/types'

import {
  BillingControlContainer,
  ButtonsContainer,
  CancelButton,
  SendButton,
  TableUtils,
} from './styles'
import { Theme } from '../../../styles/theme'

import { Table } from '../../../components/Table'
import { SettingsOutlined, Visibility } from '@mui/icons-material'
import { CircularProgress, Tooltip } from '@mui/material'
import { FloatingCheckboxes } from '../../../components/FloatingCheckboxes'
import { ActionTableButton } from '../../../components/Table/ActionTableButton'
import { DateFilter } from '../../../components/Filter/DateFilter'
import Modal from '../../../components/Modal'
import Loading from '../../../components/Loading'
import DistributorFilter from '../../AggregatedPayments/components/DistributorFilter'
import SearchInput from '../../Tickets/components/SearchInput'
import PreviewInvoiceModal from './components/PreviewInvoiceModal'

import { tableColumns } from './utils'
import {
  deleteInvoice,
  getAllInvoices,
  uploadInvoicesNew,
} from '../../../services/requests/user-requests'
import axios from 'axios'
import { v4 } from 'uuid'

export function ReportEdit() {
  const { toast } = useToast()
  const navigate = useNavigate()

  function handleDrive(id: string) {
    const url = `${process.env.REACT_APP_BASE_URL}/faturas/download/${id}`
    window.open(url, '_blank')
  }

  const [dateSelected, setDateSelected] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  })
  const [openModal, setOpenModal] = useState(false)
  const [search, setSearch] = useState('')
  const [distributorFilter, setDistributorFilter] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isReanalyzing, setIsReanalyzing] = useState(false)
  const [data, setData] = useState<IInvoiceEditData['data']>([])
  const [page, setPage] = useState(1)
  const [totalCountInvoices, setTotalCountInvoices] = useState(0)
  const [deleteInvoiceId, setDeleteInvoiceId] = useState(0)
  const [pageItemsAmount, setPageItemsAmount] = useState(30)
  const [sortValues, setSortValues] = useState<{ field: string; sort: string }>()
  const [modelInvoices, setModelInvoices] = useState([
    { field: 'cliente_nome', sort: undefined },
    { field: 'potencia_instalada', sort: undefined },
    { field: 'count_ucs', sort: undefined },
    { field: 'boletos', sort: undefined },
  ])
  const [previewingFechamentoId, setPreviewFechamentoId] = useState<number>()

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const filter = {
        ...(search ? { search } : {}),
        ...(distributorFilter.length > 0 ? { distributor: distributorFilter } : {})
      }

      const { data, count } = await getAllInvoices({
        ...dateSelected,
        page,
        limit: pageItemsAmount,
        sort: sortValues?.field,
        order: sortValues?.sort,
        ...(Object.keys(filter).length > 0 ? { filter } : {}),
      })
      setData(data ?? [])
      setTotalCountInvoices(count ?? 0)
    } catch (error) {
      toast({
        message: "Ocorreu um erro ao buscar as informações.",
        type: "error"
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isReanalyzing) return 

    fetchData()
  }, [dateSelected, sortValues, page, distributorFilter, search, isReanalyzing])

  const handleRereadInvoice = async (invoiceId: number) => {
    if (isReanalyzing) {
      toast({
        message: "A releitura da fatura esta em andamento, por favor aguarde um momento.",
        type: "info"
      })

      return
    }

    const invoice = data?.[invoiceId]

    const isBufferAvailable = !!invoice?.drive_id?.length
    const isAlreadyFromAI = invoice?.versao_leitor === 'azure'
    const isPollingReader = invoice?.versao_leitor?.startsWith('aws') || invoice?.versao_leitor === 'openai' || invoice?.versao_leitor === 'deepseek'

    if (!isBufferAvailable) {
      toast({
        message: "Não é possível fazer releitura, o arquivo não foi encontrado",
        type: "warning"
      })

      return
    }

    if (isPollingReader) {
      toast({
        message: "Não é possível fazer releitura de faturas enviadas pelo Deepseek, OpenAI ou AWS",
        type: "warning"
      })

      return
    }

    if (invoice?.versao_leitor === 'demonstrativo') {
      toast({
        message: "Não é possível fazer releitura de faturas enviadas por demonstrativo",
        type: "warning"
      })

      return
    }

    const invoiceType = isAlreadyFromAI ? invoice?.versao_leitor as 'aws' | 'azure' : 'automatico'
    await rereadInvoice(invoice.drive_id, invoiceType)
  }

  const rereadInvoice = async (driveId: string, invoiceType: 'aws' | 'azure' | 'automatico') => {
    const bufferUrl = `https://api.labs-lumi.com.br/faturas/show/${driveId}`
    const types = {
      "ERROR": "error",
      "SUCCESS": "success",
      "INFO": "warning",
    } as const

    try {
      setIsReanalyzing(true)

      const bufferResponse = await axios.get<Blob>(bufferUrl, {
        responseType: 'blob',
      }).catch(() => ({}) as Record<string, any>)

      if (!bufferResponse?.data?.size) {
        throw new Error("Não foi possivel baixar a fatura")
      }

      const id = v4()
      const file = {
        file: new File([bufferResponse?.data], `${id}.pdf`, { type: "application/pdf" }),
        fileId: id
      }

      switch (invoiceType) {
        case "azure": {
          toast({
            message: "Fazendo releitura através dos leitores da Azure (1-2 minutos)",
            type: "info"
          })

          const response = await uploadInvoicesNew({
            files: [file],
            isReanalyze: true,
            reader: invoiceType,
            uploadOrigin: 'ADMIN'
          })

          const invoice = response?.data?.at(0) ?? {}

          const status = invoice?.status as keyof typeof types
          const message = invoice?.message ?? "Processamento da fatura foi concluido, verifique se os dados foram lidos de forma correta."

          toast({
            message,
            type: types[status] ?? types.INFO
          })

          break
        }
        default: {
          toast({
            message: "Fazendo releitura através dos leitores Manuais (1-2 minutos)",
            type: "info"
          })

          const response = await uploadInvoicesNew({
            files: [file],
            isReanalyze: true,
            reader: invoiceType,
            uploadOrigin: 'ADMIN'
          })

          const invoice = response?.data?.at(0) ?? {}

          const status = invoice?.status as keyof typeof types
          const message = invoice?.message ?? "Processamento da fatura foi concluido, verifique se os dados foram lidos de forma correta."

          toast({
            message,
            type: types[status] ?? types.INFO
          })
        }
      }
    } catch(err) {
      const error = err as Error

      toast({
        message: error?.message ?? "Ocorreu um erro ao tentar re-ler a fatura.",
        type: "error"
      })
    } finally {
      setIsReanalyzing(false)
    }
  }

  function handleChangePage(page: number) {
    setPage(page)
  }

  return (
    <BillingControlContainer>
      {typeof previewingFechamentoId !== 'undefined' && (
        <PreviewInvoiceModal
          previewingFechamentoId={previewingFechamentoId}
          onClose={() => setPreviewFechamentoId(undefined)}
        />
      )}

      <Modal
        title={`Remover Fatura`}
        openModal={openModal}
        setModalOpen={setOpenModal}
        defaultButtons={false}
        opacity={1}
        subtitle={'Você irá remover o fechamento, o pdf e a fatura do sistema permanentemente.'}
      >
        <ButtonsContainer>
          <CancelButton
            disabled={isLoading}
            type='button'
            onClick={() => {
              setOpenModal(false)
            }}
          >
            Cancelar
          </CancelButton>

          <SendButton
            onClick={async () => {
              setIsLoading(true)
              await deleteInvoice(deleteInvoiceId)
                .then(() => fetchData())
                .finally(() => {
                  setIsLoading(false)
                  setOpenModal(false)
                })
            }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={20} /> : `Remover`}
          </SendButton>
        </ButtonsContainer>
      </Modal>

      <DateFilter onChange={(value) => setDateSelected(value)} currentValue={dateSelected} />

      <TableUtils>
        <DistributorFilter
          isFilterActive
          values={distributorFilter}
          onFilter={(values) => {
            setPage(1)
            setDistributorFilter(
              values
                .filter((value) => (values.length === 0 ? false : value.checked))
                .map((value) => Number(value.id)),
            )
          }}
        />

        <SearchInput
          style={{ marginLeft: 'auto', marginRight: '16px' }}
          onSearch={value => setSearch(value)}
        />

        <FloatingCheckboxes
          pagination={{
            selected: pageItemsAmount,
            setSelected: setPageItemsAmount,
            options: [15, 20, 30, 50, 100],
          }}
          isFilterActive={false}
          title='Configurar Tabela'
          submitBtnText='Salvar'
          customActionComponent={
            <ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>
          }
          submitAction={() => fetchData()}
        />
      </TableUtils>

      {isLoading ? (
        <Loading message={"Carregando dados"} />
      ) : (
        <Table
          pagination={{
            page,
            total: totalCountInvoices,
            limit: pageItemsAmount,
            totalRegistersPerPage: data?.length || 0,
            onChangePage: handleChangePage,
          }}
          columns={[
            ...tableColumns,
            {
              field: 'preview',
              headerName: '',
              sortable: false,
              width: 45,
              renderCell: (params: any) => {
                return (
                  <Tooltip title={<p style={{ fontSize: 15 }}>Fatura de Locação</p>} placement='bottom'>
                    <Visibility
                      onClick={() => setPreviewFechamentoId(params.row.fechamento_id)}
                      style={{ cursor: 'pointer', color: Theme.colors.grayDark }}
                      fontSize='medium'
                    />
                  </Tooltip>
                )
              },
            }
          ]}
          rows={data.map((item, index) => ({ ...item, id: index }))}
          alignText='center'
          sortEvent={setSortValues}
          serverSorting
          messageNoRow='Não há faturas.'
          headerModel={modelInvoices}
          setHeaderModel={setModelInvoices}
          handleEdit={(invoiceId) => {
            const invoice = data?.[invoiceId]

            localStorage.setItem('report-page', 'default')
            navigate(`/report?update=${invoice?.id}`)
          }}
          handlePrint={(invoiceId) => {
            const invoice = data?.[invoiceId]

            if (invoice && invoice.drive_id) {
              handleDrive(invoice.drive_id)
            }
          }}
          handleReloadInvoice={handleRereadInvoice}
          handleDelete={async (invoiceId) => {
            const invoice = data[invoiceId]

            setOpenModal(true)
            setDeleteInvoiceId(invoice.id)
          }}
        />
      )}
    </BillingControlContainer>
  )
}
