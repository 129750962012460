import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import writeXlsxFile from 'write-excel-file'
import CalendarIcon from '../../../assets/icons/calendar-icon.svg'
import DownloadIcon from '../../../assets/icons/download-icon.svg'
import { GlobalContext } from '../../../context/global/global'
import { BarChartPayload } from '../../../services/requests/types'
import { convertDateToMonthName } from '../../../utils/dateToName'
import { handleMoneyFormat } from '../../../utils/formatMoney'
import Widget from '../../Widget'
import './style.css'
import {
  ButtonsDiv,
  CalendarDiv,
  CalendarText,
  ChartBody,
  DownloadDiv,
  LegendCircle,
  LegendDiv,
  LegendSubDiv,
  LegendText,
  OptionItem,
  OptionList,
  TitleDiv,
  TitleText,
} from './styles'
import {
  dashboardBalanceDownloadExcel,
  dashboardEnergyDownloadExcel,
} from '../../../services/requests/user-requests'

interface BarProps {
  data: BarChartPayload[]
  isConsumer?: boolean
  dateSelected: { month: number; year: number }
  getSelectedIds?: () => number[] | undefined
}

export default function BarChart({ data, isConsumer, dateSelected, getSelectedIds }: BarProps) {
  const { Theme } = React.useContext(GlobalContext)

  const generatedEnergy = data.filter((item) =>
    isConsumer ? item.campo === 'custo_com_gd' : item.campo === 'geradora',
  )
  const consumedEnergy = data.filter((item) =>
    isConsumer ? item.campo === 'custo_sem_gd' : item.campo === 'consumido',
  )
  const reimbursedEnergy = data.filter((item) =>
    isConsumer ? item.campo === 'ganho' : item.campo === 'compensado',
  )

  const withGdCost = data.filter((item) => item.campo === 'custo_com_gd')
  const withoutGdCost = data.filter((item) => item.campo === 'custo_sem_gd')
  const gainValue = data.filter((item) => item.campo === 'ganho')

  const maxInterval = 13

  const getMonths = data
    .slice(0, maxInterval)
    .map((value) => convertDateToMonthName(value.mes_referencia))

  const [value, setValue] = useState({
    id: maxInterval,
    name: 'Últimos 12 meses',
  })

  const calendarOption = [
    {
      id: maxInterval,
      name: 'Últimos 12 meses',
    },
    {
      id: 6,
      name: 'Últimos 6 meses',
    },
    {
      id: 3,
      name: 'Últimos 3 meses',
    },
  ]

  const handleSeriesChange = (id: number) => {
    removeFromIds.has(id) ? removeFromIds.delete(id) : removeFromIds.add(id)
    const currentStartingValue = isConsumer ? ConsumerStartingValue : startingValue
    setApexSeries(
      removeFromIds.size >= 3
        ? currentStartingValue
        : currentStartingValue.filter((_, index: number) => !removeFromIds.has(index)),
    )
    setCurrentColor(
      removeFromIds.size >= 3
        ? colors
        : colors.filter((_, index: number) => !removeFromIds.has(index)),
    )
    if (removeFromIds.size >= 3) removeFromIds.clear()
  }

  const [showDropDown, setShowDropDown] = useState(false)
  const [removeFromIds] = useState(new Set())
  const startingValue = [
    {
      name: 'Energia Gerada',
      data: generatedEnergy.map((item) => Number(item?.valor?.toFixed(0) || 0)),
    },
    {
      name: 'Energia Consumida',
      data: consumedEnergy.map((item) => Number(item?.valor?.toFixed(0) || 0)),
    },
    {
      name: 'Energia Compensada',
      data: reimbursedEnergy.map((item) => Number(item?.valor?.toFixed(0) || 0)),
    },
  ]

  const ConsumerStartingValue = [
    {
      name: 'Custo sem GD',
      data: withoutGdCost.map((item) => item.valor),
    },
    {
      name: 'Custo com GD',
      data: withGdCost.map((item) => item.valor),
    },
    {
      name: 'Ganho',
      data: gainValue.map((item) => item.valor),
    },
  ]

  const colors = [Theme.colors.lightGreen, Theme.colors.orange, Theme.colors.darkishGreen]

  const [currentColor, setCurrentColor] = useState(colors)
  const [apexSeries, setApexSeries] = useState(isConsumer ? ConsumerStartingValue : startingValue)

  useEffect(() => {
    if (data) {
      setApexSeries(isConsumer ? ConsumerStartingValue : startingValue)
    }
  }, [data])

  const chartOptions = {
    options: {
      fill: {
        opacity: 1,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      legend: {
        show: false,
        markers: {
          radius: 100,
        },
        itemMargin: {
          horizontal: 24,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        followCursor: true,
        style: {
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontweight: '400',
          fontSize: '12px',
        },
        x: {
          show: false,
        },
        y: {
          formatter: function(value: number) {
            return isConsumer
              ? `${handleMoneyFormat(value, true)}`
              : `${Number(value?.toFixed(2) || 0).toLocaleString('pt-BR')} kWh`
          },
        },
        marker: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '65',
          borderRadius: value.id === 31 ? 1 : 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: currentColor,
      chart: {
        id: 'bar-chart',
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
      },
      xaxis: {
        categories: getMonths.slice(maxInterval - value.id, maxInterval),
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
          color: '#808080',
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        min: 0,
        tickAmount: 5,
        labels: {
          formatter: (value: number) => {
            return Number(value?.toFixed(2) || 0).toLocaleString('pt-BR')
          },
        },
        forceNiceScale: true,
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#808080',
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },
        axisBorder: {
          show: true,
          color: '#808080',
          offsetX: 0,
          offsetY: 0,
        },
      },
    },
    series: apexSeries.map((item) => ({
      ...item,
      data: item.data.slice(maxInterval - value.id, maxInterval),
    })),
  }

  const handleDownloadButton = async () => {
    if (!getSelectedIds) return

    const selectedIds = getSelectedIds()

    await dashboardBalanceDownloadExcel({
      usinas_id: selectedIds,
      month: dateSelected.month,
      year: dateSelected.year,
      periodo: value.id,
      isConsumer,
    })
      .then((response) => {
        const blob = new Blob([response.data])
        const element = document.createElement('a')
        element.href = URL.createObjectURL(blob)
        element.download = `${isConsumer ? 'desempenho_financeiro_' : 'balanco_energetico_'}${
          dateSelected.month + '_' + dateSelected.year
        }.xlsx`
        document.body.appendChild(element)
        element.click()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Widget style={{ height: '350px', zIndex: 10 }}>
      <ChartBody id='chartbody'>
        <TitleDiv>
          <TitleText>{isConsumer ? 'Desempenho financeiro' : 'Balanço Energético'}</TitleText>
          <ButtonsDiv>
            <CalendarDiv onClick={() => setShowDropDown(!showDropDown)} showDropDown={showDropDown}>
              <img src={`${CalendarIcon}`} alt='Calendar Button' />
              <CalendarText>{value.name}</CalendarText>
              <OptionList showDropDown={showDropDown}>
                {calendarOption.map((item) => (
                  <OptionItem
                    key={item.id}
                    onClick={() => {
                      setValue({ id: item.id, name: item.name })
                      setShowDropDown(false)
                    }}
                    active={value.id === item.id}
                  >
                    {item.name}
                  </OptionItem>
                ))}
              </OptionList>
            </CalendarDiv>
            {getSelectedIds &&
              <DownloadDiv
                onClick={async () => await handleDownloadButton()}
              >
                <img
                  src={`${DownloadIcon}`}
                  alt='Download Button'
                />
              </DownloadDiv>
            }
          </ButtonsDiv>
        </TitleDiv>
        <div>
          <Chart
            options={chartOptions.options}
            series={chartOptions.series}
            type='bar'
            width='100%'
            height={250}
          />
          <LegendDiv>
            <LegendSubDiv active={!removeFromIds.has(0)}>
              <LegendCircle color={Theme.colors.lightGreen} />
              <LegendText onClick={() => handleSeriesChange(0)}>
                {isConsumer ? 'Custo sem GD' : 'Energia Gerada'}{' '}
              </LegendText>
            </LegendSubDiv>
            <LegendSubDiv active={!removeFromIds.has(1)}>
              <LegendCircle color={Theme.colors.orange} />
              <LegendText onClick={() => handleSeriesChange(1)}>
                {isConsumer ? 'Custo com GD' : 'Energia Consumida'}
              </LegendText>
            </LegendSubDiv>
            <LegendSubDiv active={!removeFromIds.has(2)}>
              <LegendCircle color={Theme.colors.darkishGreen} />
              <LegendText onClick={() => handleSeriesChange(2)}>
                {isConsumer ? 'Ganho' : 'Energia Compensada'}
              </LegendText>
            </LegendSubDiv>
          </LegendDiv>
        </div>
      </ChartBody>
    </Widget>
  )
}
