import { Pagination, PaginationQuery } from '../interfaces/pagination'
import { api } from './api'
import { getFormattedMonthReference } from '../utils/date-utils'

export interface Quantitative {
  clientId: number
  clientName: string
  status: 'ACTIVE' | 'INACTIVE' | 'OVERDUE'
  totalUcsCount: number
  totalPower: number
  lastAccess: string
}
export interface DashboardSuperList {
  params: PaginationQuery & {
    monthReference: string
  }
  result: Pagination<Quantitative>
}
export interface Metric {
  count: number
  percentage: number
}
export interface DashboardSuperCards {
  params: {
    monthReference: string
    filter?: Record<string, unknown>
  }
  result: {
    totalUcs: Metric
    totalPowerPlants: Metric
    totalPower: Metric
    totalPastValueDue: Metric
  }
}
export interface DashboardSuperTableRow {
  id: number
  nameClient: string
  status: 'ACTIVE' | 'INACTIVE' | 'OVERDUE'
  ucsCount: number
  powerPlantsCount: number
  totalPower: number
  dueValues: number
  lastAccess: string
}

export interface DashboardSuperDownloadSheet {
  params: {
    referenceMonths: string[]
    filter?: Record<string, unknown>
  }
  result: Buffer
}

class DashboardSuperService {
  async cards(params: DashboardSuperCards['params']): Promise<DashboardSuperCards['result']> {
    const { monthReference, filter } = params

    const response = await api.get('dashboard/quantitative/metrics', {
      params: {
        monthReference: getFormattedMonthReference(monthReference),
        ...filter,
      },
    })
    const { ucs, powerplants, power, paid } = response.data.data
    return {
      totalPastValueDue: {
        count: paid.total,
        percentage: paid.lastMonthVariation,
      },
      totalPower: {
        count: power.total,
        percentage: power.lastMonthVariation,
      },
      totalPowerPlants: {
        count: powerplants.total,
        percentage: powerplants.lastMonthVariation,
      },
      totalUcs: {
        count: ucs.total,
        percentage: ucs.lastMonthVariation,
      },
    }
  }

  async list(params: DashboardSuperList['params']): Promise<DashboardSuperList['result']> {
    const { monthReference, filter, itemsPerPage, page, sort } = params
    const response = await api.get('/dashboard/quantitative', {
      params: {
        monthReference: getFormattedMonthReference(monthReference),
        itemsPerPage,
        page,
        ...(sort || {}),
        ...filter,
      },
    })

    const { data, pageInfo } = response.data

    const formattedData = data.map(
      ({
        id,
        nameClient,
        ucsCount,
        powerPlantsCount,
        totalPower,
        dueValues,
        status,
        lastAccess,
      }: DashboardSuperTableRow) => ({
        clientId: id,
        clientName: nameClient,
        totalPower,
        powerPlantsCount,
        totalUcsCount: ucsCount,
        dueValues,
        status,
        lastAccess,
      }),
    )

    return {
      data: formattedData,
      pageInfo,
    }
  }

  async downloadSpreadSheet(params: DashboardSuperDownloadSheet['params']): Promise<Blob> {
    const { referenceMonths, filter } = params

    const response = await api.get('/dashboard/quantitative/spreadsheet', {
      responseType: 'blob',
      params: {
        referenceMonths: referenceMonths.join(','),
        ...filter,
      },
    })

    return new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
  }
}

export const dashboardSuperService = new DashboardSuperService()
