import {
  ButtonsPowerPlantContainer,
  ConfigButtonsColumn,
  TableContainer,
  TotalPower,
} from './style'

import { AddOutlined, SettingsOutlined } from '@mui/icons-material'
import { Alert, Snackbar } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import moment from 'moment'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import DownloadIcon from '../../../../assets/download-icon-white.svg'
import { FloatingCheckboxes } from '../../../../components/FloatingCheckboxes'
import Loading from '../../../../components/Loading'
import ModalDelete from '../../../../components/Modal/ModalDelete'
import ModalStatus from '../../../../components/Modal/ModalStatus'
import ModalSuccess from '../../../../components/Modal/ModalSuccess'
import { SearchInput } from '../../../../components/SearchInput'
import { ActionTableButton } from '../../../../components/Table/ActionTableButton'
import { GlobalContext } from '../../../../context/global/global'
import { useToast } from '../../../../hooks/useToast'
import { CheckboxType } from '../../../../interfaces/checkbox'
import {
  deletePowerPlant,
  getInvestorReport,
  myUnitsPowerPlants,
  powerPlantsDownload,
  updateStatusUC,
} from '../../../../services/requests/user-requests'
import dateFormatForRequest from '../../../../utils/dateFormatForRequest'
import { DownloadDiv } from '../../../Credits/Allocation/styles'
import { IMyUnitsPageProps } from '../types'
import { myUnitsPowerPlantsType } from './types'
import { usePermissions } from '../../../../hooks/usePermissions'
import { PermissionType } from '../../../../interfaces/permissions'
import { getPowerPlantsColumns } from './utils'
import { TableV2 } from '../../../../components/TableV2'
import { TablePagination } from '../../../../components/TableV2/components/TablePagination'
import { Sort } from '../../../../interfaces/conference'
import ModalRegisterPowerPlant from '../../../../components/Modal/MyUnits/Registers/ModalRegisterPowerPlant'

export function PowerPlant() {
  const { checkPermission } = usePermissions()
  const { mes_ref } = useOutletContext<IMyUnitsPageProps>()

  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState(1)
  const [totalCountPowerPlants, setTotalCountPowerPlants] = useState(0)
  const [powerPlants, setPowerPlants] = useState<myUnitsPowerPlantsType[]>([])
  const [filteredPowerPlants, setFilteredPowerPlants] = useState<myUnitsPowerPlantsType[]>()
  const [isRegisterOpen, setIsRegisterOpen] = useState(false)
  const [isStatusOpen, setIsStatusOpen] = useState(false)
  const [statusMessage] = useState('Status de contrato alterado com sucesso!')
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [isSuccessOpen, setIsSuccessOpen] = useState(false)
  const [totalPower, setTotalPower] = useState(0)
  const [selectedIds, setSelectedIds] = useState<number[]>([0])
  const [loading, setLoading] = useState(false)
  const [selectedPowerPlant, setSelectedPowerPlant] = useState<myUnitsPowerPlantsType>()
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [ready, setReady] = useState(false)
  const [sortValues, setSortValues] = useState<Sort>()
  const [pageItemsAmount, setPageItemsAmount] = useState(30)
  const { toast } = useToast()

  const { state } = useContext(GlobalContext)

  const userId = state.auth?.customer?.id

  function handleChangePage(page: number) {
    setPage(page)
  }

  async function handleReport(powerPlantId: number) {
    try {
      const investorReport = await getInvestorReport(powerPlantId, mes_ref, userId)
      const blob = new Blob([investorReport.data], { type: 'application/pdf' })

      const fileUrl = URL.createObjectURL(blob)

      window.open(fileUrl, '_blank')
      setTimeout(() => {
        URL.revokeObjectURL(fileUrl)
      }, 10000)
    } catch {
      toast({
        type: 'error',
        message: 'Erro ao gerar relatório',
      })
    }
  }

  useEffect(() => {
    if (search !== undefined) {
      const delayDebounce = setTimeout(() => {
        if (search.length >= 3 || search.length === 0) {
          setPage(1)
          setReady(!ready)
        }
      }, 1000)

      return () => clearTimeout(delayDebounce)
    }
  }, [search])

  const handleStatus = async (id: number) => {
    const powerPlant = powerPlants.find((item) => item.id === id)
    if (!powerPlant) return

    setSelectedPowerPlant(powerPlant)

    try {
      const response = await updateStatusUC(powerPlant.id, mes_ref!)

      const contractStatus = response.data.contractStatus

      setPowerPlants((prevPowerPlants) => {
        return prevPowerPlants.map((prevPowerPlant) => {
          if (prevPowerPlant.id === powerPlant.id) {
            return {
              ...prevPowerPlant,
              status_ativacao_uc: contractStatus,
            }
          }
          return prevPowerPlant
        })
      })

      setIsStatusOpen(true)
    } catch (error) {
      console.error('Erro ao atualizar o status da UC:', error)
    }
  }

  const getData = useCallback(() => {
    setLoading(true)
    myUnitsPowerPlants({
      page,
      filter: search,
      limit: pageItemsAmount,
      order: sortValues?.order ?? 'ASC',
      sort: sortValues?.field ?? 'nome',
      customersId: [],
      powerPlantId: [],
      mes_ref: mes_ref ?? dateFormatForRequest(new Date()),
    })
      .then((response) => {
        setLoading(false)
        response.data?.meta?.total && setTotalCountPowerPlants(response.data.meta.total)
        response.data?.usinas && setPowerPlants(response.data.usinas)
        setFilteredPowerPlants(undefined)
        setTotalPower(response.data.total_power)
      })
      .catch(() => {
        setErrorMessage('Erro ao carregar os dados de usinas.')
        setLoading(false)
      })
  }, [mes_ref, page, pageItemsAmount, search, sortValues?.field, sortValues?.order])

  useEffect(() => {
    getData()
  }, [getData])

  const handleAdd = () => {
    setIsRegisterOpen(true)
    // TODO: criar modo para atualizar tabela
  }

  const deleteFunction = async () => {
    try {
      setLoading(true)
      const response = await deletePowerPlant(selectedIds)
      console.log('response', response.data)
      setIsSuccessOpen(true)
      getData()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleDownloadButton = async () => {
    await powerPlantsDownload({
      sort: sortValues?.field,
      order: sortValues?.order,
      filter: search,
    })
      .then((response) => {
        const blob = new Blob([response.data])
        const element = document.createElement('a')
        element.href = URL.createObjectURL(blob)
        element.download = `usinas_${moment().format('DD_MM_YYYY')}.xlsx`

        document.body.appendChild(element)
        element.click()
      })
      .catch((err) => console.log(err))
  }

  const handleDelete = (ids: number[] | undefined = undefined) => {
    if (!ids && selectedIds.length < 1) return

    ids && setSelectedIds(ids)
    setIsDeleteOpen(true)
  }

  const handleEdit = (powerPlantId: number) => {
    const powerPlant = powerPlants.find((item) => item.id === powerPlantId)

    setSelectedPowerPlant(powerPlant)
    setIsEditOpen(true)
  }

  const [tableCols, setTableCols] = useState<GridColDef[]>([])

  useEffect(() => {
    const data = filteredPowerPlants ?? powerPlants
    if (!data?.length || !checkPermission) return

    const hasEditPermission = checkPermission(PermissionType.MY_UNITS_POWER_PLANT_UPDATE)
    const hasDeletePermission = checkPermission(PermissionType.MY_UNITS_POWER_PLANT_DELETE)

    const columns = getPowerPlantsColumns({
      data,
      deleteHandler: (id: number) => handleDelete([id]),
      editHandler: handleEdit,
      statusHandler: handleStatus,
      reportHandler: userId === 218 || userId === 4621 ?
        (powerPlantId: number) => handleReport(powerPlantId) :
        undefined,
      permissions: {
        hasEditPermission,
        hasDeletePermission
      }
    })

    setTableCols((state) => {
      const getFromState = (name?: string) => state.find(item => item.headerName === name)

      const newColumns = columns.reduce((acc, item) => {
        const name = item.headerName
        const oldItem = getFromState(name)

        item.hide = oldItem?.hide ?? item?.hide
        acc.push(item)
        return acc
      }, [] as GridColDef[])

      return newColumns
    })
  }, [filteredPowerPlants, powerPlants, checkPermission])

  const handleSelectTableColumns = (checkboxes: CheckboxType[]) => {
    setTableCols((cols) =>
      cols.map((col) => ({
        ...col,
        hide: !checkboxes.find((box) => box.label === col.headerName)?.checked,
      })),
    )
  }

  const hasCreatePermission = checkPermission(PermissionType.MY_UNITS_POWER_PLANT_CREATE)

  const rowsCurrentPage = filteredPowerPlants ?? powerPlants

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <TableContainer>
        <ButtonsPowerPlantContainer>
          <ConfigButtonsColumn gap={'1rem'}></ConfigButtonsColumn>
          <ConfigButtonsColumn gap='1rem'>
            <TotalPower>
              <span>Potência total instalada:</span>{' '}
              {totalPower.toLocaleString('pt-BR', { maximumFractionDigits: 0 })} kW
            </TotalPower>
            <SearchInput value={search} setValue={setSearch} style={{ width: 180 }} />
            <ConfigButtonsColumn gap='0.5rem'>
              <DownloadDiv>
                <img src={`${DownloadIcon}`} alt='Download Button' onClick={handleDownloadButton} />
              </DownloadDiv>
              <FloatingCheckboxes
                pagination={{
                  selected: pageItemsAmount,
                  setSelected: setPageItemsAmount,
                  options: [15, 20, 30, 50, 100],
                }}
                isFilterActive={false}
                title='Configurar Tabela'
                options={tableCols.map((col) => ({
                  label: col.headerName || '',
                  checked: !col.hide,
                  id: Math.random(),
                }))}
                customActionComponent={
                  <ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>
                }
                submitBtnText='Salvar'
                submitAction={handleSelectTableColumns}
              />
              {hasCreatePermission && (
                <ActionTableButton onClick={handleAdd} icon={<AddOutlined />} styles={{ fontSize: '15px' }}>
                  Adicionar
                </ActionTableButton>
              )}
            </ConfigButtonsColumn>
          </ConfigButtonsColumn>
        </ButtonsPowerPlantContainer>
        <TableV2
          columns={tableCols}
          rows={rowsCurrentPage}
          alignText='center'
          isSelectable={true}
          messageNoRow='Não há usinas cadastradas.'
          serverSorting
          setSelectedItems={setSelectedIds}
          sort={sortValues}
          onSort={(sort) => {
            setSortValues(sort)
            setPage(1)
          }}
        >
          <TablePagination
            pageInfo={{
              totalCount: totalCountPowerPlants,
              currentPage: page,
              totalPages: Math.ceil(totalCountPowerPlants / pageItemsAmount),
              limit: pageItemsAmount
            }}
            currentPageItemCount={rowsCurrentPage?.length}
            onChange={handleChangePage}
          />
        </TableV2>
      </TableContainer>

      {isRegisterOpen ? (
        <ModalRegisterPowerPlant
          onClose={() => setIsRegisterOpen(false)}
          postAction={getData}
        />
      ) : null}
      {isStatusOpen && (
        <ModalStatus
          buttonText='Ok, obrigado'
          buttonColor='darkGreen'
          text={statusMessage}
          openModal={isStatusOpen}
          setModalOpen={setIsStatusOpen}
        />
      )}
      {isEditOpen && (
        <ModalRegisterPowerPlant
          onClose={() => setIsEditOpen(false)}
          editValues={selectedPowerPlant}
          postAction={getData}
        />
      )}
      {isDeleteOpen && (
        <ModalDelete
          openModal={isDeleteOpen}
          setModalOpen={setIsDeleteOpen}
          buttonColor='darkGreen'
          text='Tem certeza que deseja excluir a Usina?'
          action={deleteFunction}
        />
      )}
      {isSuccessOpen && (
        <ModalSuccess
          buttonText='Ok, obrigado'
          buttonColor='darkGreen'
          text='Usina excluída com sucesso!'
          openModal={isSuccessOpen}
          setModalOpen={setIsSuccessOpen}
        />
      )}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
    </>
  )
}
