import { Dispatch, SetStateAction, useState } from 'react'
import {
  BulkUpdateQuotas,
  GetQuotasFromCsv,
  onboardingService,
} from '../../../../../services/onboarding'
import EntityItems from '../EntityItems'
import { useToast } from '../../../../../hooks/useToast'

interface Props {
  managerId: number
  quotasFromCsv: GetQuotasFromCsv['result']
  setQuotasFromCsv: Dispatch<SetStateAction<GetQuotasFromCsv['result'] | undefined>>
}

export default function QuotasToUpdate(props: Props) {
  const { managerId, quotasFromCsv, setQuotasFromCsv } = props

  const [isLoading, setLoading] = useState<boolean>(false)

  const { toast } = useToast()

  const bulkUpdateQuotas = async (values: BulkUpdateQuotas['params']['quotas']): Promise<void> => {
    try {
      setLoading(true)
      await onboardingService.bulkUpdateQuotas({
        managerId,
        quotas: values,
      })
      setQuotasFromCsv((previousRecipientUcs) =>
        previousRecipientUcs
          ? {
              ...previousRecipientUcs,
              quotasToUpdate: [...previousRecipientUcs.quotasToUpdate].filter(
                (currentUc) => !values.find((val) => val.id === currentUc.id),
              ),
            }
          : undefined,
      )
    } catch (error) {
      console.log(error)
      toast({
        message: 'Erro ao atualizar cotas',
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <EntityItems
      errors={quotasFromCsv.errors}
      isLoading={isLoading}
      itemsCompleted={quotasFromCsv.quotasCreatedCount}
      itemsToUpdate={quotasFromCsv.quotasToUpdate.length}
      itemsCreated={quotasFromCsv.quotasCreated}
      entities={quotasFromCsv.quotasToUpdate}
      compareSearchFilter={(searchFilter, quota) =>
        quota.uc.name.toLowerCase().includes(searchFilter) ||
        quota.uc.code.toLowerCase().includes(searchFilter)
      }
      getEntityIdentifier={(quota) => quota.id}
      getUpdateFormProps={(quota) => {
        return {
          title: `${quota.uc.name} (${quota.uc.code})`,
          changes: quota.changes,
          onSubmit: (values) =>
            bulkUpdateQuotas([
              {
                data: {
                  powerPlantId: values.uc_geradora_id as number,
                  percentValue: values.percentual_valor as number,
                },
                id: quota.id,
              },
            ]),
        }
      }}
    />
  )
}
