import { GridColDef } from '@mui/x-data-grid'
import * as React from 'react'
import { Box, DataGrid, Icon, PaginationContainer, StatusIcon } from './styles'

import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import { createTheme, Pagination, ThemeProvider, Tooltip } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import { DefaultTheme } from 'styled-components'
import SortedDescIcon from '../../assets/arrow-drop-down.svg'
import SortedAscIcon from '../../assets/arrow-drop-up.svg'
import UnsortedIcon from '../../assets/arrow-drop.svg'
import DeleteIcon from '../../assets/delete.svg'
import EditIcon from '../../assets/edit.svg'
import PaperRedIcon from '../../assets/paper-red.svg'
import PaperIcon from '../../assets/paper.svg'
import ReloadIcon from '../../assets/reload.svg'
import SendIcon from '../../assets/send-gray.svg'
import NotSendIcon from '../../assets/send-red.svg'
import { GlobalContext } from '../../context/global/global'

interface Props {
  columns: GridColDef[]
  rows: Record<string, unknown>[]
  styles?: SxProps<Theme> | undefined
  handleEdit?: (id: any) => void
  isEditDisabled?: boolean
  handleStatus?: (id: any) => void
  isStatusDisabled?: boolean
  handleDelete?: (id: any) => void
  isDeleteDisabled?: boolean
  handleSend?: (id: any) => void
  isSendDisabled?: boolean
  handlePrint?: (id: any) => void
  handleReport?: (id: any) => void
  handleReloadInvoice?: (id: any) => void
  handleEye?: (id: any) => void
  alignText?: 'center' | 'left' | 'right'
  handleAdmin?: (id: any) => void
  handleTicket?: (id: any) => void
  isSelectable?: boolean
  setSelectedItems?: React.Dispatch<React.SetStateAction<number[]>>
  messageNoRow?: string
  pagination?: {
    page: number
    total: number
    limit: number
    totalRegistersPerPage?: number
    onChangePage: (page: number) => void
  }
  changeHeaderColor?: string
  sortEvent?: any
  serverSorting?: boolean
  headerModel?: Array<{ field: string; sort: 'asc' | 'desc' | undefined | null }>
  setHeaderModel?: any
}

const muiTheme = (Theme: DefaultTheme) =>
  createTheme({
    palette: {
      primary: {
        main: Theme.colors.green,
      },
    },
  })

export const Table: React.FC<Props> = ({
  columns,
  rows,
  styles,
  handleStatus,
  isStatusDisabled,
  handleEdit,
  isEditDisabled,
  handleDelete,
  isDeleteDisabled,
  handleSend,
  isSendDisabled,
  handleEye,
  handlePrint,
  handleReport,
  handleReloadInvoice,
  alignText = 'left',
  isSelectable,
  setSelectedItems,
  pagination,
  changeHeaderColor,
  messageNoRow,
  sortEvent,
  serverSorting,
  headerModel,
  setHeaderModel,
}) => {
  const { Theme } = React.useContext(GlobalContext)

  const tableRef = React.useRef<HTMLDivElement>(null)
  const [formattedColumns, setFormattedColumns] =
    React.useState<GridColDef<any, any, any>[]>(columns)

  React.useEffect(() => {
    const cols = columns.map((c) => ({
      ...c,
      align: alignText,
      headerAlign: alignText,
    })) as GridColDef<any, any, any>[]
    setFormattedColumns(cols)
  }, [])

  React.useEffect(() => {
    if (!tableRef.current?.clientWidth) return
    const containerWidth = tableRef.current.clientWidth

    let offsetWidth = handleSend ? 10 : 50
    if (handleStatus) offsetWidth += 70
    if (handleDelete) offsetWidth += 70
    if (handleSend) offsetWidth += 70
    if (handlePrint) offsetWidth += 70
    if (handleReport) offsetWidth += 70
    if (handleEye) offsetWidth += 70
    if (isSelectable) offsetWidth += 60

    const numCols = columns.reduce((numCols, currCol) => {
      offsetWidth += currCol.width ?? 0
      return numCols + (currCol.hide || currCol.width ? 0 : 1)
    }, 0)

    const cols = columns.map((col) => ({
      ...col,
      width: col.width ?? (containerWidth - offsetWidth) / numCols,
    })) as GridColDef<any, any, any>[]

    if (!isStatusDisabled && handleStatus)
      cols.push({
        field: 'status',
        headerName: '',
        sortable: false,
        width: 45,
        renderCell: (params: any) => {
          return (
            <Tooltip
              title={<p style={{ fontSize: 15 }}>Alterar status de contrato</p>}
              placement='bottom'
            >
              <StatusIcon
                onClick={() => handleStatus(params.row.id)}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          )
        },
      })

    if (!isEditDisabled && handleEdit)
      cols.push({
        field: 'edit',
        headerName: '',
        sortable: false,
        width: 45,
        renderCell: (params: any) => {
          return (
            <Tooltip title={<p style={{ fontSize: 15 }}>Editar</p>} placement='bottom'>
              <img
                src={EditIcon.toString()}
                onClick={() => handleEdit(params.row.id)}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          )
        },
      })

    if (handleReloadInvoice)
      cols.push({
        field: 'reload',
        headerName: '',
        sortable: false,
        width: 45,
        renderCell: (params: any) => {
          return (
            <Tooltip title={<p style={{ fontSize: 15 }}>Releitura da fatura</p>} placement='bottom'>
              <img
                src={ReloadIcon.toString()}
                onClick={() => handleReloadInvoice(params.row.id)}
                style={{ cursor: 'pointer', maxWidth: '24px' }}
              />
            </Tooltip>
          )
        },
      })

    if (!isDeleteDisabled && handleDelete)
      cols.push({
        field: 'delete',
        headerName: '',
        sortable: false,
        width: 45,
        renderCell: (params: any) => {
          return (
            <Tooltip title={<p style={{ fontSize: 15 }}>Deletar</p>} placement='bottom'>
              <img
                src={DeleteIcon.toString()}
                onClick={() => handleDelete(params.row.id)}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          )
        },
      })

    if (!isSendDisabled && handleSend)
      cols.push({
        field: 'send',
        headerName: '',
        sortable: false,
        width: 45,
        renderCell: (params: any) => {
          const statusToNotSend = [
            'DISABLED',
            'CANCELLING'
          ]
          if (statusToNotSend.includes(params.row.status_ativacao_uc) || params.row.blocked_invoices) {
            return <img src={NotSendIcon.toString()} style={{ cursor: 'not-allowed' }} />
          }

          return (
            <Tooltip title={<p style={{ fontSize: 15 }}>Enviar cobrança</p>} placement='bottom'>
              <img
                src={SendIcon.toString()}
                onClick={() => handleSend(params.row.id)}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          )
        },
      })

    if (handleEye) {
      cols.push({
        field: 'eye',
        headerName: '',
        sortable: false,
        width: 45,
        renderCell(params) {
          return (
            <Tooltip title={<p style={{ fontSize: 15 }}>Visualizar cobrança</p>} placement='bottom'>
              <Icon onClick={() => handleEye(params.row.id)} style={{ cursor: 'pointer' }} />
            </Tooltip>
          )
        },
      })
    }

    if (handlePrint)
      cols.push({
        field: 'print',
        headerName: '',
        sortable: false,
        width: 45,
        renderCell(params) {
          if (!params.row.drive_id) {
            return (
              <Tooltip
                title={<p style={{ fontSize: 15 }}>Dados obtidos a partir do demonstrativo</p>}
                placement='bottom'
              >
                <img
                  src={PaperRedIcon.toString()}
                  onClick={() => handlePrint(params.row.id)}
                  style={{ cursor: 'not-allowed' }}
                />
              </Tooltip>
            )
          }

          return (
            <Tooltip title={<p style={{ fontSize: 15 }}>Visualizar fatura</p>} placement='bottom'>
              <img
                src={PaperIcon.toString()}
                onClick={() => handlePrint(params.row.id)}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          )
        },
      })

    if (handleReport)
      cols.push({
        field: 'report',
        headerName: '',
        sortable: false,
        width: 45,
        renderCell(params) {
          return (
            <Tooltip
              title={<p style={{ fontSize: 15 }}>Visualizar relatório do investidor</p>}
              placement='bottom'
            >
              <AssessmentOutlinedIcon
                onClick={() => handleReport(params.row.id)}
                fontSize='medium'
                style={{ cursor: 'pointer', color: '#ababab' }}
              />
            </Tooltip>
          )
        },
      })

    setFormattedColumns(cols)
  }, [tableRef.current?.clientWidth, columns, isSendDisabled, isDeleteDisabled, isEditDisabled])

  const lastPage = pagination
    ? Math.ceil((pagination.total === 0 ? 1 : pagination.total) / pagination.limit)
    : 0

  const handleSorting = (value: string | undefined | null) => {
    if (value === undefined || value === null) {
      return 'asc'
    }
    if (value === 'asc') {
      return 'desc'
    }

    if (value === 'desc') {
      return undefined
    }
  }

  return (
    <ThemeProvider theme={muiTheme(Theme)}>
      {formattedColumns && (
        <Box sx={styles}>
          <DataGrid
            ref={tableRef}
            changeHeaderColor={changeHeaderColor ?? ''}
            rows={rows}
            columns={formattedColumns}
            checkboxSelection={isSelectable}
            disableSelectionOnClick
            sortModel={headerModel}
            style={{
              border: 0,
            }}
            sortingMode={serverSorting ? 'server' : 'client'}
            experimentalFeatures={{ newEditingApi: true }}
            disableColumnMenu={true}
            autoHeight
            components={{
              ColumnSortedDescendingIcon: () => <img src={SortedDescIcon.toString()} />,
              ColumnSortedAscendingIcon: () => <img src={SortedAscIcon.toString()} />,
              ColumnUnsortedIcon: () => <img src={UnsortedIcon.toString()} />,
              NoRowsOverlay: () => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <p>{messageNoRow ? messageNoRow : 'No rows'}</p>
                </div>
              ),
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'RowEven' : 'RowOdd'
            }
            onColumnHeaderClick={(e) => {
              if (headerModel && headerModel.find((item) => item.field === e.field)) {
                const updateHeaderModel = headerModel.map((item) =>
                  item.field === e.field
                    ? {
                        ...item,
                        sort: handleSorting(item.sort),
                      }
                    : { ...item, sort: undefined },
                )
                const currentItem = headerModel.filter((item) => item.field === e.field)[0]
                if (currentItem) {
                  const element = {
                    field: currentItem?.field,
                    sort: handleSorting(currentItem.sort),
                  }
                  updateHeaderModel.splice(
                    headerModel.map((item) => item.field).indexOf(e.field),
                    1,
                  )
                  updateHeaderModel.unshift(element)
                }
                setHeaderModel(updateHeaderModel)
                sortEvent({
                  field: currentItem?.field,
                  sort: handleSorting(currentItem.sort),
                })
              }
            }}
            hideFooterSelectedRowCount={true}
            hideFooterPagination={true}
            onSelectionModelChange={(selectedItems) => {
              setSelectedItems && setSelectedItems(selectedItems as number[])
            }}
          />
          {pagination && (
            <PaginationContainer>
              <p>
                Mostrando {pagination.totalRegistersPerPage + ' '}
                de {' ' + pagination.total} ítens
              </p>
              <Pagination
                defaultPage={pagination.page}
                count={lastPage}
                color='primary'
                onChange={(_, page) => {
                  pagination.onChangePage(page)
                }}
              />
            </PaginationContainer>
          )}
        </Box>
      )}
    </ThemeProvider>
  )
}
