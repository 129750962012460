import { Dispatch, SetStateAction, useState } from 'react'
import {
  BulkUpdateRecipientUcs,
  GetRecipientUcsFromCsv,
  onboardingService,
} from '../../../../../services/onboarding'
import EntityItems from '../EntityItems'
import { useToast } from '../../../../../hooks/useToast'

interface Props {
  managerId: number
  recipientUcsFromCsv: GetRecipientUcsFromCsv['result']
  setRecipientUcsFromCsv: Dispatch<SetStateAction<GetRecipientUcsFromCsv['result'] | undefined>>
}

export default function RecipientUcsToUpdate(props: Props) {
  const { managerId, recipientUcsFromCsv, setRecipientUcsFromCsv } = props

  const [isLoading, setLoading] = useState<boolean>(false)

  const { toast } = useToast()

  const bulkUpdateRecipientUcs = async (
    values: BulkUpdateRecipientUcs['params']['recipientUcs'],
  ): Promise<void> => {
    try {
      setLoading(true)
      await onboardingService.bulkUpdateRecipientUcs({
        managerId,
        recipientUcs: values,
      })
      setRecipientUcsFromCsv((previousRecipientUcs) =>
        previousRecipientUcs
          ? {
              ...previousRecipientUcs,
              ucsToUpdate: [...previousRecipientUcs.ucsToUpdate].filter(
                (currentUc) => !values.find((val) => val.codigo === currentUc.codigo),
              ),
            }
          : undefined,
      )
    } catch (error) {
      console.log(error)
      toast({
        message: 'Erro ao atualizar UC',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <EntityItems
      errors={recipientUcsFromCsv.errors}
      isLoading={isLoading}
      itemsCompleted={recipientUcsFromCsv.ucsCreatedCount}
      itemsCreated={recipientUcsFromCsv.ucsCreated}
      itemsToUpdate={recipientUcsFromCsv.ucsToUpdate.length}
      entities={recipientUcsFromCsv.ucsToUpdate}
      compareSearchFilter={(searchFilter, recipientUc) =>
        recipientUc.nome.toLowerCase().includes(searchFilter) ||
        recipientUc.codigo.toLowerCase().includes(searchFilter)
      }
      getEntityIdentifier={(recipientUc) => recipientUc.id}
      bulkUpdate={(recipientUcs) => {
        bulkUpdateRecipientUcs(
          recipientUcs.map((recipientUc) => ({
            ...Object.entries(recipientUc.changes).reduce(
              (agg, [field, { after }]) => ({
                ...agg,
                [field]: after,
              }),
              {},
            ),
            codigo: recipientUc.codigo,
          })),
        )
      }}
      getUpdateFormProps={(recipientUc) => {
        return {
          title: `${recipientUc.nome} (${recipientUc.codigo})`,
          changes: recipientUc.changes,
          onSubmit: (values) => bulkUpdateRecipientUcs([{ ...values, codigo: recipientUc.codigo }]),
        }
      }}
    />
  )
}
