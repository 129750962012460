import { PaginationQuery } from '../../interfaces/pagination'
import { convertObjectToQueryParams } from '../../utils/pagination'
import { sanitizeFilename } from '../../utils/string-utils'
import { api } from '../api'

import {
  ChangePasswordPayload,
  ClientPlants,
  ConsumerEdit,
  ConsumerMapChartPayload,
  ConsumerPayload,
  ConsumerRegisterPayload,
  CreateConsumer,
  CreateUser,
  CreditsAllocation,
  CreditsAllocationTableRes,
  CreditsBalanceTableRes,
  EnergyBarChartPayload,
  IDashboardAreaChartProps,
  IFaturamentoData,
  IGetMeasurementsChartDataQueries,
  IGetMeasurementsDataProps,
  IInvoiceEditData,
  IMonthFilterProps,
  IMonthFilterWithUsinaProps,
  IUploadDemonstrative,
  IUploadGenericInvoice,
  IUploadInvoice,
  IUploadInvoices,
  IUploadQuotas,
  IgetConsumerDashboardAreaProps,
  InverterPayload,
  LoginPayload,
  MeasurementsChartData,
  MeasurementsData,
  MyUnitsGetPayload,
  MyUnitsPayloadResponse,
  PlantListPayload,
  PowerPlantEdit,
  PowerPlantRegister,
  PowerPlantsLocationsPayload,
  PowerPlantsLocationsRes,
  ResetPasswordPayload,
  UploadFile,
  UploadInvoicesOpenAI,
  myUnitsPowerPlantsGet,
  paymentsGetPayload
} from './types'

export function LoginRequest(payload: LoginPayload) {
  return api.post('/login', {
    ...payload,
  })
}

export function resetPasswordRequest(payload: ResetPasswordPayload) {
  return api.post('/password-recovery', {
    ...payload,
  })
}

export function consumerSettingsPut(payload: ConsumerPayload) {
  return api.put('/consumidor', {
    ...payload,
  })
}

export function consumerSettingsGet() {
  return api.get('/consumidor')
}

export function changePasswordRequest(payload: ChangePasswordPayload) {
  return api.put('/trocar-senha', {
    ...payload,
  })
}

export function inverterPostRequest(payload: InverterPayload) {
  return api.post('/inversor', {
    ...payload,
  })
}

export function getMeasurementsChartData({
  plantId,
  from,
  to,
  granularity,
}: IGetMeasurementsChartDataQueries) {
  const params = []
  if (plantId) params.push(`plantId=${plantId}`)
  if (from) params.push(`from=${from}`)
  if (to) params.push(`to=${to}`)
  if (granularity) params.push(`granularity=${granularity}`)

  const url = '/inversor/dados-geracao' + (params.length > 0 ? `?${params.join('&')}` : '')

  return api.get<MeasurementsChartData>(url)
}

export function getMeasurementsData({ plantId, from, to }: IGetMeasurementsDataProps) {
  return api.get<MeasurementsData>(`/inversor/medicoes?plantId=${plantId}&from=${from}&to=${to}`)
}

export function consumerRegisterPost(payload: ConsumerRegisterPayload) {
  return api.post('/consumidor', {
    ...payload,
  })
}

export function inverterSupplierGet() {
  return api.get('/inversor-fornecedores/list')
}
export function distributorRegisterGet() {
  return api.get('/distribuidoras/list')
}

export function consumerListGet(powerPlants?: number[]) {
  return api.get<{ id: number; documento_numero: string; cliente_nome: string }[]>(
    `/consumidores/list${powerPlants ? `?powerPlants=${powerPlants.join(',')}` : ''}`,
  )
}

export function powerPlantListGet() {
  return api.get<{ id: number; nome: string }[]>('/usinas/list')
}

export function distributorListGet() {
  return api.get('/distribuidoras/list')
}

export function inversorListGet() {
  return api.get('/inversor-fornecedores/list')
}

export function plantListGet(payload: PlantListPayload) {
  return api.get('/plantas/list', {
    ...payload,
  })
}

export function getAllPlantsByClientId() {
  return api.get<ClientPlants>('/inversor/client-plants')
}

export function biggestFivePowerPlantsGet() {
  return api.get('/dashboard/gerador/maiores_usinas')
}

export function dashboardWidgetsInfos({ usinas_id, month, year, filter }: IMonthFilterWithUsinaProps) {
  return api.post(`/dashboard/gerador?mes_ref=${month < 10 ? '0' + month : month}-01-${year}${filter && Object.keys(filter).length > 0 ? `&filter=${convertObjectToQueryParams(filter, ';')}` : ``}`, {
    usinas_id,
  })
}

export function dashboardDonutChartPost(usinas_id: number[]) {
  return api.post('/dashboard/gerador/status-faturas', { usinas_id })
}

export function dashboardPercentageChart({ usinas_id, month, year, filter }: IMonthFilterWithUsinaProps) {
  return api.post(
    `/dashboard/gerador/compensado-por-consumido?mes_ref=${year}-${
      month < 10 ? '0' + month : month
    }-01${(filter && Object.keys(filter).length > 0 ? `&filter=${convertObjectToQueryParams(filter, ';')}` : '')}`,
    { usinas_id },
  )
}

export function dashboardMapChart(usinas_id: number[], filter?: Record<string, unknown>) {
  return api.post(`/dashboard/gerador/mapa${filter && Object.keys(filter).length > 0 ? `?filter=${convertObjectToQueryParams(filter, ';')}` : ''}`, { usinas_id })
}

export function getdashboardConsumerMapChart({ idsList }: { idsList: string }) {
  return api.get<ConsumerMapChartPayload>(`/dashboard/consumidor/mapas?powerPlants=${idsList}`)
}

export function dashboardBarChart({ usinas_id, month, year, filter }: IMonthFilterWithUsinaProps) {
  return api.post(
    `/dashboard/gerador/balanco?mes_ref=${year}-${month < 10 ? '0' + month : month}-01${filter && Object.keys(filter).length > 0 ? `&filter=${convertObjectToQueryParams(filter, ';')}` : ''}`,
    { usinas_id, periodo: 12 },
  )
}

export function dashboardAreaChart({
  usinas_id,
  energyType,
  month,
  year,
  filter,
}: IDashboardAreaChartProps) {
  return api.post(
    `/dashboard/gerador/area?mes_ref=${year}-${month < 10 ? '0' + month : month}-01${filter && Object.keys(filter).length > 0 ? `&filter=${convertObjectToQueryParams(filter, ';')}` : ''}`,
    { usinas_id, periodo: 12, campo: energyType },
  )
}

export function unitRegisterPost(payload: any) {
  return api.post('/ucscreate', payload)
}

export function editUnitConsumer(payload: any) {
  const { id, ...rest } = payload
  return api.put(`/ucs/${id}`, {
    data: rest,
  })
}

export function ucsListConsumer(cliente_id_consumidor: number) {
  return api.get<{ id: number; nome: string; numero_uc: string }[]>(
    `/ucs/list?cliente_id_consumidor=${cliente_id_consumidor}`,
  )
}

export function registerPowerPlantPost(payload: PowerPlantRegister) {
  return api.post('/usina', {
    ...payload,
  })
}

export function editPowerPlant(payload: PowerPlantEdit) {
  return api.put(`/usinas/${payload.id}`, {
    ...payload,
  })
}
export function editConsumer(payload: ConsumerEdit) {
  return api.put(`/consumidores/${payload.id}`, {
    ...payload,
  })
}

export async function getInvestorReport(powerPlantId: number, mesRef?: string, managerId?: number) {
  return api.get(`/relatorio/${powerPlantId}`, {
    responseType: 'blob',
    params: {
      mes_ref: mesRef,
      managerId,
    },
  })
}

export function deletePowerPlant(payload: number[]) {
  return api.delete(`/usinas?usinas_id=${payload.join('&usinas_id=')}`)
}

export function deleteConsumer(payload: number[]) {
  return api.delete(`/consumidores?consumidores_id=${payload.join('&consumidores_id=')}`)
}

export function deleteUC(payload: number[]) {
  return api.delete(`/ucs?ucs_id=${payload.join('&ucs_id=')}`)
}

export function updateStatusUC(ucId: number, referenceMonth: string) {
  return api.put(`/ucs/${ucId}/status-de-contrato/${referenceMonth}`)
}

export function myUnitsGet(props?: IMonthFilterProps) {
  if (props) {
    const { month, year } = props

    if (month && year) {
      return api.get<MyUnitsGetPayload>(
        `/minhas-unidades?mes_ref=${year}-${month < 10 ? '0' + month : month}-01`,
      )
    }
  }

  return api.get<MyUnitsGetPayload>('/minhas-unidades')
}

export function paymentsGet({
  sort,
  filter = null,
  limit = 10,
  order = 'ASC',
  page = 1,
  distributorsId = [],
  powerPlantsId = [],
  status_ativacao_uc,
  day,
  monthsRefs,
  status,
}: paymentsGetPayload) {
  let url = `/pagamentos/tabela?filter=${filter}&limit=${limit}&order=${order}&page=${page}`

  url += `&sort=${sort || 'cliente_consumidor'}`

  if (monthsRefs) {
    url += `&monthsRefs=${monthsRefs.join(',')}`
  }
  if (day) {
    url += `&dia=${day}`
  }
  if (status) {
    url += `&status=${status}`
  }
  if (status_ativacao_uc) {
    url += `&status_ativacao_uc=${status_ativacao_uc}`
  }
  url += distributorsId.map((item) => `&distribuidora_id=${item}`)
  url += powerPlantsId.map((item) => `&usina_id=${item}`)
  url = url.replace(/,/g, '')
  return api.get(url)
}

interface PaymentsInfoGetRequest extends PaginationQuery {
  distributorsId: number[]
  powerPlantsId: number[]
  day?: number
  monthReference: string
  status?: string
}

export function paymentsInfoGetAllgreen({
  sort,
  filter,
  limit = 10,
  order = 'ASC',
  page = 1,
  distributorsIds = [],
  powerPlantsIds = [],
  year,
  month,
  day,
  status,
}: {
  page: number
  distributorsIds: number[]
  powerPlantsIds: number[]
  sort?: string
  order?: string
  year?: number
  month?: number
  day?: number
  status: string
  filter?: PaginationQuery['filter']
  limit?: number
}) {
  let url = `/pagamentos?limit=${limit}&order=${order}&page=${page}`
  if (status) {
    url += `&status=${status}`
  }
  if (filter && Object.keys(filter).length > 0) {
    url += `&filter=${convertObjectToQueryParams(filter)}`
  }
  if (sort) {
    url += `&sort=${sort}`
  }
  if (year) {
    url += `&ano=${year}`
  }
  if (month) {
    url += `&mes=${month}`
  }
  if (day) {
    url += `&dia=${day}`
  }
  url += distributorsIds.map((item) => `&distribuidora_id=${item}`)
  url += powerPlantsIds.map((item) => `&usina_id=${item}`)
  
  url = url.replace(/,/g, '')
  return api.get(url)
}

export async function paymentsInfoGet(params: PaymentsInfoGetRequest) {
  const {
    distributorsId,
    powerPlantsId,
    day,
    monthReference,
    status,
    filter,
  } = params

  const queryParams = {
    ...(distributorsId ? {distributorsId: distributorsId.join(',')} : {}),
    ...(powerPlantsId ? {powerPlantsId: powerPlantsId.join(',')} : {}),
    ...(filter && Object.keys(filter).length > 0
      ? {
          filter: convertObjectToQueryParams(filter)
        }
      : {}),
    ...(status ? { status } : {}),
    ...(day ? { dueDayReference: day } : {}),
    monthReference,

  }
  const result = await api.get('/pagamentos/metrics', {
    params: queryParams,
  })
  return result.data
}

export function downloadCsvGetAllgreen({
  sort = 'uc_nome',
  filter,
  order = 'ASC',
  distributorsIds = [],
  powerPlantsIds = [],
  status_ativacao_uc,
  monthsRefs,
  status,
  day,
  companies
}: {
  status_ativacao_uc?: string | null
  distributorsIds: number[]
  powerPlantsIds: number[]
  sort?: string
  order?: string
  monthsRefs: string[]
  status: string
  filter?: PaginationQuery['filter']
  day?: number
  companies?: number[]
}) {
  let url = `/pagamentos/csv?order=${order}&status=${status}`
  if (filter && Object.keys(filter).length > 0) {
    url += `&filter=${convertObjectToQueryParams(filter, ';')}`
  }
  if (sort) {
    url += `&sort=${sort}`
  }
  if (monthsRefs) {
    for (const monthsRef of monthsRefs) {
      url += `&monthsRefs=${monthsRef}`
    }
  }

  if (day) {
    url += `&dia=${day}`
  }
  if (status_ativacao_uc) {
    url += `&status_ativacao_uc=${status_ativacao_uc}`
  }
  url += distributorsIds.map((item) => `&distribuidora_id=${item}`)
  url += powerPlantsIds.map((item) => `&usina_id=${item}`)

  url = url.replace(/,/g, '')
  return api.get(url, {
    responseType: 'blob',
  })
}

interface DownloadCsvGet {
  params: PaginationQuery & {
    distributorsId: number[]
    powerPlantsId: number[]
    dueDayReference?: number
    monthReference: string[]
    status?: number
  }
  result: any
}

export function downloadCsvGet(request: DownloadCsvGet['params']) {
  const {
    sort,
    distributorsId,
    powerPlantsId,
    dueDayReference,
    monthReference,
    status,
    filter,
  } = request
  console.log(filter)
  const queryParams = {
    distributorsId: distributorsId.join(','),
    powerPlantsId: powerPlantsId.join(','),
    ...(sort
      ? {
          order: sort.order,
          field: sort.field,
        }
      : {
          field: 'cliente_consumidor',
          order: 'asc',
        }),
    ...(filter && Object.keys(filter).length > 0
      ? {
          filter: convertObjectToQueryParams(filter)
        }
      : {}),
    ...(status ? { status } : {}),
    ...(dueDayReference ? { dueDayReference } : {}),
    monthReference: monthReference.join(','),
  }
  return api.get('/pagamentos/spreadsheet', {
    responseType: 'blob',
    params: queryParams,
  })
}

export function myUnitsPowerPlants({
  filter = null,
  limit = 10,
  order = 'ASC',
  page = 1,
  sort = 'nome',
  mes_ref,
}: myUnitsPowerPlantsGet) {
  return api.get(
    `/usinas?filter=${filter}&limit=${limit}&order=${order}&page=${page}&sort=${sort}&mes_ref=${mes_ref}`,
  )
}

export function myUnitsUCs({
  filter = null,
  limit = 10,
  order = 'ASC',
  page = 1,
  sort = 'nome',
  customersId = [],
  powerPlantId = [],
  distributorsId = [],
  mes_ref,
}: myUnitsPowerPlantsGet) {
  const url = `/ucs?filter=${filter}&limit=${limit}&order=${order}&page=${page}&sort=${sort}&mes_ref=${mes_ref}`

  return api.post<MyUnitsPayloadResponse>(url, {
    customersId,
    powerPlantId,
    distributorsId
  })
}

export function listAllUcsCodes() {
  return api.get<MyUnitsPayloadResponse>(`/ucs/listAllUcsCodes`).then((res) => res.data)
}

export function getReportValues({
  codigo,
  mes_referencia,
}: {
  codigo: string
  mes_referencia?: string
}) {
  return api
    .get<MyUnitsPayloadResponse>(
      `/ucs/create-report?codigo=${codigo}&&mes_referencia=${mes_referencia}`,
    )
    .then((res) => res.data)
}

export function myConsumers({
  filter = null,
  limit = 10,
  order = 'ASC',
  page = 1,
  sort = 'cliente_nome',
  mes_ref,
}: myUnitsPowerPlantsGet) {
  return api.get(
    `/consumidores?filter=${filter}&limit=${limit}&order=${order}&page=${page}&sort=${sort}&mes_ref=${mes_ref}`,
  )
}

export function powerPlantsLocations(payload: PowerPlantsLocationsPayload) {
  return api.post<PowerPlantsLocationsRes>('/dashboard/gerador/mapa', {
    ...payload,
  })
}

export function invoicesOverviewGet({
  page = 1,
  distributorsId,
  powerPlantsIds,
  consumersId,
  year,
  month,
  status,
  filter,
  isPowerPlant,
  customFilter,
  isYear = false,
  isDashboard = false,
}: {
  page: number
  consumersId?: number[]
  distributorsId: number[]
  powerPlantsIds: number[]
  year?: number
  month?: number
  status?: string
  filter?: string
  isPowerPlant?: string
  isYear?: boolean
  isDashboard?: boolean
  customFilter?: Record<string, unknown>
}) {
  let url = `/faturas/visao-geral?page=${page}`

  if (year) {
    url += `&ano=${year}`
  }

  if (month) {
    url += `&mes=${month}`
  }

  if (status) {
    url += `&status=${status}`
  }

  if (isYear) {
    url += `&isYear=true`
  }

  if (isDashboard) {
    url += `&isDashboard=true`
  }

  if (filter) {
    url += `&filter=${filter}`
  }
  if (isPowerPlant) {
    url += `&isPowerPlant=${isPowerPlant}`
  }
  if(customFilter && Object.keys(customFilter).length > 0) {
    url += `&customFilter=${convertObjectToQueryParams(customFilter, ';')}`
  }

  return api.post(url, {
    consumersId,
    powerPlantsIds,
    distributorsId,
  })
}

export function invoicesPowerPlantsGet({
  distributorsId,
  powerPlantsIds,
  year,
  month,
  status,
  filter,
  page = 1,
  sort,
  order,
  itemsPerPage,
}: {
  page: number
  distributorsId: number[]
  powerPlantsIds: number[]
  sort?: string
  order?: string
  year?: number
  month?: number
  status?: string
  filter?: string
  itemsPerPage?: number
}) {
  let url = `/faturas/usinas?page=${page}`
  url += distributorsId.map((item) => `&distribuidoras_id=${item}`)
  url += powerPlantsIds.map((item) => `&usinas_id=${item}`)

  if (year) {
    url += `&ano=${year}`
  }

  if (month) {
    url += `&mes=${month}`
  }

  if (status) {
    url += `&status=${status}`
  }

  url += `&sort=${sort || 'usina_nome'}`

  url += `&order=${order || 'ASC'}`

  if (filter) {
    url += `&filter=${filter}`
  }

  if (itemsPerPage) {
    url += `&limit=${itemsPerPage}`
  }

  url = url.replace(/,/g, '')
  return api.get(`${url}`)
}

export function invoicesDistributorsGet({
  distributorsId,
  powerPlantsIds,
  year,
  month,
  status,
  filter,
  page = 1,
  sort,
  order,
  itemsPerPage,
}: {
  page: number
  distributorsId: number[]
  powerPlantsIds: number[]
  year?: number
  month?: number
  status?: string
  filter?: string
  sort?: string
  order?: string
  itemsPerPage?: number
}) {
  let url = `/faturas/beneficiarias?page=${page}`
  url += distributorsId.map((item) => `&distribuidoras_id=${item}`)
  url += powerPlantsIds.map((item) => `&usina_id=${item}`)

  if (year) {
    url += `&ano=${year}`
  }

  if (month) {
    url += `&mes=${month}`
  }

  if (status) {
    url += `&status=${status}`
  }

  if (sort) {
    url += `&sort=${sort}`
  }

  if (order) {
    url += `&order=${order}`
  }

  if (filter) {
    url += `&filter=${filter}`
  }

  if (itemsPerPage) {
    url += `&limit=${itemsPerPage}`
  }

  url = url.replace(/,/g, '')
  return api.get(`${url}`)
}

export function invoicesHistory({
  consumersId,
  year,
  filter,
  page = 1,
  powerPlantsId = [],
  distributorsId = [],
  sort,
  order,
  limit,
}: {
  page?: number
  consumersId: number[]
  year?: number
  filter?: string
  sort?: string
  powerPlantsId?: number[]
  distributorsId?: number[]
  order?: string
  limit: number
}) {
  let url = `/faturas/historico?page=${page}&limit=${limit ?? 15}`

  if (year) {
    url += `&ano=${year}`
  }

  url += `&sort=${sort || 'nome'}`

  url += `&order=${order || 'ASC'}`

  if (filter) {
    url += `&filter=${filter}`
  }

  return api.post(url, {
    consumersId,
    powerPlantsId,
    distributorsId,
  })
}

export function getOneInvoice(id: string) {
  return api.get(`/super/invoice/${id}`).then((res) => res.data)
}

export function invoicesHistoryLocation({
  consumersId,
  year,
  filter,
  page = 1,
  powerPlantsId = [],
  distributorsId = [],
  sort,
  order,
  limit,
}: {
  page?: number
  consumersId: number[]
  year?: number
  filter?: string
  sort?: string
  powerPlantsId?: number[]
  distributorsId?: number[]
  order?: string
  limit: number
}) {
  let url = `/faturas/historico/location?page=${page}&limit=${limit ?? 15}`

  if (year) {
    url += `&ano=${year}`
  }

  url += `&sort=${sort || 'nome'}`

  url += `&order=${order || 'ASC'}`

  if (filter) {
    url += `&filter=${filter}`
  }

  url = url.replace(/,/g, '')
  return api.post(url, {
    consumersId,
    powerPlantsId,
    distributorsId,
  })
}

export function recalculateBilling({ fatura_id }: { fatura_id: number[] | string[] }) {
  return api.post('/fechamentos/recalculate', {
    fatura_id,
  })
}

export function creditsAllocationInfos({
  powerPlantIds,
  year,
  month,
}: {
  powerPlantIds?: (number | string)[]
  year?: number | string
  month?: number | string
}) {
  const params = []
  if (powerPlantIds && powerPlantIds.length > 0)
    params.push(`usina_id=${powerPlantIds.join('&usina_id=')}`)
  if (year) params.push(`ano=${year}`)
  if (month) params.push(`mes=${month}`)
  const url = `/alocacao-creditos${params.length > 0 ? '?' + params.join('&') : ''}`

  return api.get<CreditsAllocation>(url)
}

export function creditsAuditoryInfos({
  powerPlantIds,
  year,
  month,
}: {
  powerPlantIds?: (number | string)[]
  year?: number | string
  month?: number | string
}) {
  const params = []
  if (powerPlantIds && powerPlantIds.length > 0)
    params.push(`usina_id=${powerPlantIds.join('&usina_id=')}`)
  if (year) params.push(`ano=${year}`)
  if (month) params.push(`mes=${month}`)
  const url = `/auditoria-creditos${params.length > 0 ? '?' + params.join('&') : ''}`

  return api.get<CreditsAllocation>(url)
}

export function creditsAllocationsTable({
  sort,
  order,
  page = 1,
  filter,
  powerPlantIds,
  year,
  month,
  itemsPerPage,
}: {
  sort?: string
  order?: string
  page?: number
  filter?: string
  powerPlantIds?: (number | string)[]
  year?: number | string
  month?: number | string
  itemsPerPage?: number
}) {
  const params = []
  params.push(`sort=${sort ? sort : 'estoque_em_meses'}`)
  params.push(`order=${order ? order : 'desc'}`)
  if (page) params.push(`page=${page}`)
  if (filter) params.push(`filter=${filter}`)
  if (powerPlantIds && powerPlantIds.length > 0)
    params.push(`usina_id=${powerPlantIds.join('&usina_id=')}`)
  if (year) params.push(`ano=${year}`)
  if (month) params.push(`mes=${month}`)
  if (itemsPerPage) params.push(`limit=${itemsPerPage}`)

  const url = '/alocacao-creditos/tabela' + (params.length > 0 ? `?${params.join('&')}` : '')

  return api.get<CreditsAllocationTableRes>(url)
}

export function creditsAuditsTable({
  sort,
  order,
  page = 1,
  filter,
  powerPlantIds,
  year,
  month,
  itemsPerPage,
}: {
  sort?: string
  order?: string
  page?: number
  filter?: string
  powerPlantIds?: (number | string)[]
  year?: number | string
  month?: number | string
  itemsPerPage?: number
}) {
  const params = []
  params.push(`sort=${sort ? sort : 'uc_nome'}`)
  params.push(`order=${order ? order : 'asc'}`)
  if (page) params.push(`page=${page}`)
  if (filter) params.push(`filter=${filter}`)
  if (powerPlantIds && powerPlantIds.length > 0)
    params.push(`usina_id=${powerPlantIds.join('&usina_id=')}`)
  if (year) params.push(`ano=${year}`)
  if (month) params.push(`mes=${month}`)
  if (itemsPerPage) params.push(`limit=${itemsPerPage}`)

  const url = '/auditoria-creditos/tabela' + (params.length > 0 ? `?${params.join('&')}` : '')

  return api.get<CreditsAllocationTableRes>(url)
}

// <<<<<<< HEAD
// export function creditsAllocationDownload({ usina_id }: { usina_id: number | string }) {
//   return api.get(`/cotas/csv?usina_id=${usina_id}`, {
// =======
export function creditsAllocationDownload({
  usina_id,
  mes,
  ano,
  sort = 'uc_nome',
  order = 'asc',
  filter,
}: {
  usina_id: number | string
  mes: number
  ano: number
  sort?: string
  order?: string
  filter?: string
}) {
  return api.get(
    `/cotas/csv?usina_id=${usina_id}&mes=${mes}&ano=${ano}&&sort=${sort}&&order=${order}${
      filter ? `&&filter=${filter}` : ''
    }`,
    {
      responseType: 'blob',
    },
  )
}

export function creditsAuditoryDownload({
  usina_id,
  mes,
  ano,
  sort = 'uc_nome',
  order = 'asc',
  filter,
}: {
  usina_id: number | string
  mes: number
  ano: number
  sort?: string
  order?: string
  filter?: string
}) {
  return api.get(
    `/auditoria-creditos/csv?usina_id=${usina_id}&mes=${mes}&ano=${ano}&&sort=${sort}&&order=${order}${
      filter ? `&&filter=${filter}` : ''
    }`,
    {
      responseType: 'blob',
    },
  )
}

export function creditsBalanceInfos({
  filter,
  consumersId,
  powerPlantIds,
  year,
  month,
}: {
  filter?: PaginationQuery['filter']
  powerPlantIds?: (string | number)[]
  consumersId?: (string | number)[]
  year?: number | string
  month?: number | string,
}) {
  const params = []

  if (filter && Object.keys(filter).length > 0) {
    params.push(`filter=${convertObjectToQueryParams(filter, ';')}`)
  }

  if (year) params.push(`ano=${year}`)
  if (month) params.push(`mes=${month}`)
  const url = `/balance/monthly/summary${params.length > 0 ? '?' + params.join('&') : ''}`

  return api.post<Record<string, {
    valor: number,
    percentual: number
  }>>(url, {
    powerPlantIds,
    consumerIds: consumersId
  })
}

export function creditsBalanceTable({
  filter,
  sort,
  order,
  consumersId,
  powerPlantIds,
  year,
  month,
  page = 1,
  itemsPerPage,
}: {
  filter?: PaginationQuery['filter']
  sort?: string
  order?: string
  page?: number
  powerPlantIds?: (string | number)[]
  consumersId?: (string | number)[]
  year?: number | string
  month?: number | string
  itemsPerPage?: number
}) {
  const params = []

  if (filter && Object.keys(filter).length > 0) {
    params.push(`filter=${convertObjectToQueryParams(filter, ';')}`)
  }
  params.push(`sort=${sort ? sort : 'remuneracao_gerador'}`)
  params.push(`order=${order ? order : 'desc'}`)

  if (page) params.push(`page=${page}`)
  if (year) params.push(`ano=${year}`)
  if (month) params.push(`mes=${month}`)
  if (itemsPerPage) params.push(`limit=${itemsPerPage}`)
  
  const url = `/balance/monthly/table${params.length > 0 ? '?' + params.join('&') : ''}`

  return api.post<CreditsBalanceTableRes>(url, {
    powerPlantIds,
    consumersId,
  })
}

export function creditsBalanceDownload({
  filter,
  sort,
  order,
  consumerId,
  powerPlantIds,
  year,
  month,
  page = 1,
  columns,
  monthsRefs,
}: {
  monthsRefs: string[]
  filter?: PaginationQuery['filter']
  sort?: string
  order?: string
  page?: number
  powerPlantIds?: (string | number)[]
  consumerId?: (string | number)[]
  year?: number | string
  month?: number | string
  columns?: { [key: string]: boolean }[]
}) {
  const params = []

  if (filter && Object.keys(filter).length > 0) {
    params.push(`filter=${convertObjectToQueryParams(filter, ';')}`)
  }
  params.push(`sort=${sort ? sort : 'remuneracao_gerador'}`)
  params.push(`order=${order ? order : 'desc'}`)
  if (monthsRefs && monthsRefs.length > 0) params.push(`monthsRefs=${monthsRefs.join(',')}`)
  if (page) params.push(`page=${page}`)
  if (year) params.push(`ano=${year}`)
  if (month) params.push(`mes=${month}`)
  const url = `/balanco-mensal/csv${params.length > 0 ? '?' + params.join('&') : ''}`

  return api.post(
    url,
    {
      powerPlantIds,
      consumerId,
      columns,
    },
    { responseType: 'blob' },
  )
}

export function invoiceDownload({
  powerPlantsIds,
  monthsRefs,
  month,
  year,
  filter,
  sort,
  order,
}: {
  powerPlantsIds: number[]
  sort?: string
  order?: string
  year?: number
  month?: number
  monthsRefs?: string[]
  filter?: string
  page?: number
  limit?: number
}) {
  let url = `/faturas/csv`

  url += powerPlantsIds?.map((item, index) => `${index === 0 ? '?' : '&'}usinas_id=${item}`)

  if (year) {
    url += `&ano=${year}`
  }

  if (month) {
    url += `&mes=${month}`
  }

  if (monthsRefs) {
    for (const monthsRef of monthsRefs) {
      url += `&monthsRefs=${monthsRef}`
    }
  }

  url += `&sort=${sort || 'usina_nome'}`

  url += `&order=${order || 'ASC'}`

  if (filter) {
    url += `&filter=${filter}`
  }

  url = url.replace(/,/g, '')
  return api.get(url, { responseType: 'blob' })
}

export function invoiceDownloadPending({
  powerPlantsIds,
  monthsRefs,
  year,
  month,
  filter,
  sort,
  order,
}: {
  powerPlantsIds: number[]
  sort?: string
  order?: string
  year?: number,
  month?: number,
  monthsRefs?: string[]
  filter?: string
  page?: number
  limit?: number
}) {
  let url = `/invoices/pending/csv`

  url += powerPlantsIds?.map((item, index) => `${index === 0 ? '?' : '&'}usinas_id=${item}`)

  if (year) {
    url += `&ano=${year}`
  }

  if (month) {
    url += `&mes=${month}`
  }

  if (monthsRefs) {
    for (const monthsRef of monthsRefs) {
      url += `&monthsRefs=${monthsRef}`
    }
  }

  url += `&sort=${sort || 'usina_nome'}`

  url += `&order=${order || 'ASC'}`

  if (filter) {
    url += `&filter=${filter}`
  }

  url = url.replace(/,/g, '')
  return api.get(url, { responseType: 'blob' })
}

export function powerPlantsDownload({
  sort,
  order,
  filter,
}: {
  sort?: string
  order?: string
  filter?: string
}) {
  const params = []
  if (sort) params.push(`sort=${sort}`)
  if (order) params.push(`order=${order}`)
  if (filter) params.push(`filter=${filter}`)

  const url = `/usinas/csv${params.length > 0 ? '?' + params.join('&') : ''}`

  return api.get(url, { responseType: 'blob' })
}

export function replicateQuotas({
  powerPlantId,
  year,
  month,
}: {
  powerPlantId: number
  year: number
  month: number
}) {
  return api.post<{ status: string; message: string }>('/cotas/replicar-anterior', {
    usina_id: powerPlantId,
    ano: year,
    mes: month,
  })
}

export function createQuota({
  ucId,
  powerPlantId,
  year,
  month,
  quota,
}: {
  ucId: number
  powerPlantId: number
  year: number
  month: number
  quota: number
}) {
  return api.post<{ status: string; message: string }>('/cotas', {
    uc_consumidora_id: ucId,
    uc_geradora_id: powerPlantId,
    ano: year,
    mes: month,
    cota: quota,
  })
}

export function editQuota({
  ucId,
  powerPlantId,
  year,
  month,
  quota,
  id,
}: {
  ucId: number
  powerPlantId: number
  year: number
  month: number
  quota: number
  id: number
}) {
  return api.put<{ status: string; message: string }>(`/cotas/${id}`, {
    uc_consumidora_id: ucId,
    uc_geradora: powerPlantId,
    ano: year,
    mes: month,
    cota: quota,
  })
}

export function invoiceClosePut({
  id,
  fechamento_id,
  remuneracao_geracao,
  vencimento,
}: {
  id: number
  fechamento_id: number
  remuneracao_geracao: number
  vencimento: string
}) {
  return api.put(`/fechamentos/${id}`, {
    fechamento_id,
    remuneracao_geracao,
    vencimento,
  })
}

interface IsendInvoicesPostProps extends IMonthFilterProps {
  fechamentos_id: number[]
}

export function sendInvoicesPost({ fechamentos_id, month, year }: IsendInvoicesPostProps) {
  return api.post('pagamentos/enviar-cobranca', {
    fechamentos_id,
    month,
    year,
  })
}

export function resendInvoicesPost({ fechamentos_id, month, year }: IsendInvoicesPostProps) {
  return api.post('pagamentos/reenviar-cobranca', {
    fechamentos_id,
    month,
    year,
  })
}

interface IgetInvoiceDataProps extends IMonthFilterProps {
  fechamento_id: number
}

export function getInvoiceData({ fechamento_id, month, year }: IgetInvoiceDataProps) {
  return api.post('/pagamentos/preview-cobranca', {
    fechamento_id,
    month,
    year,
  })
}

export function getInvoiceDataSuper(fechamentoId: number) {
  return api.post('/pagamentos/preview-cobranca-super', {
    fechamento_id: fechamentoId,
  })
}

export function getInvoiceDataLocation({ drive_id }: { drive_id: string }) {
  return api
    .post('/pagamentos/preview-cobranca/location', {
      drive_id,
    })
    .then((res) => res.data)
}

export function deleteQuota(id: number) {
  return api.delete<{ status: string; message: string }>(`/cotas/${id}`)
}

// DASHBOARD CONSUMER
export function getConsumerDashboard({ month, year, idsList }: IMonthFilterProps) {
  return api.get(
    `/dashboard/consumidor?mes_ref=${
      month < 10 ? '0' + month : month
    }-01-${year}&&powerPlants=${idsList}`,
  )
}

export function getConsumerDashboardInvoiceStatus(props?: IMonthFilterProps) {
  let url = '/dashboard/consumidor/status-faturas'

  if (props) {
    const { month, year } = props
    url = `${url}?mes_ref=${year}-${month < 10 ? '0' + month : month}-01&&powerPlants=${
      props.idsList
    }${props.onlyYear ? '&&only_year=true' : ''}`
  }
  return api.get(url)
}

export function getConsumerDashboardArea({
  month,
  year,
  periodo,
  idsList,
}: IgetConsumerDashboardAreaProps) {
  return api.get(
    `/dashboard/consumidor/area?periodo=${periodo}&mes_ref=${year}-${
      month < 10 ? '0' + month : month
    }-01&&powerPlants=${idsList}`,
  )
}

export function getConsumerDashboardBars({ month, year, idsList }: IMonthFilterProps) {
  return api.get(
    `/dashboard/consumidor/barras?periodo=12&mes_ref=${year}-${
      month < 10 ? '0' + month : month
    }-01&&powerPlants=${idsList}`,
  )
}

export function getConsumerDashboardEnergyBars({ month, year, idsList }: IMonthFilterProps) {
  return api.get<EnergyBarChartPayload>(
    `/dashboard/gerador/previsao-consumo?mes_ref=${year}-${
      month < 10 ? '0' + month : month
    }-01&&powerPlants=${idsList}`,
  )
}

export async function uploadInvoice({ invoice, clientId, uploadOrigin }: IUploadInvoice) {
  try {
    const formData = new FormData()

    clientId?.length && formData.append('clientId', clientId)
    formData.append('file', invoice)
    if (uploadOrigin) {
      formData.append('uploadOrigin', uploadOrigin)
    }

    const response = await api.post(`/faturas`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response
  } catch (error) {
    console.log(error)
    throw error
  }
}

export async function uploadGenericInvoice({ invoice }: IUploadGenericInvoice) {
  try {
    const formData = new FormData()
    formData.append('file', invoice)

    const response = await api.post(`/invoices/excel`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response
  } catch (error) {
    console.log(error)
    throw error
  }
}

export async function uploadDemonstrative({ file, distributor, referenceMonth }: IUploadDemonstrative) {
  const formData = new FormData()

  formData.append('file', file)
  formData.append('distributor', distributor)
  formData.append('referenceMonth', referenceMonth)

  const response = await api.post('/demonstrative/user/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return response
}

export async function uploadQuotas({ file, referenceMonth }: IUploadQuotas) {
  const formData = new FormData()

  formData.append('file', file)
  formData.append('referenceMonth', referenceMonth)

  const response = await api.post('/quota/user/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return response
}

export async function uploadInvoicesNew({ files, uploadOrigin, reader, readerVariant, isReanalyze, hasMuc }: IUploadInvoices) {
  try {
    const formData = new FormData()
    for (const fileInfo of files) {
      formData.append('files', fileInfo.file, sanitizeFilename(fileInfo.file.name))
    }

    if (uploadOrigin) formData.append('uploadOrigin', uploadOrigin)
    
    if (hasMuc) formData.append('hasMuc', String(hasMuc))
    if (isReanalyze) formData.append('isReanalyze', String(isReanalyze))

    if (reader) formData.append('reader', String(reader))
    if (readerVariant) formData.append('readerVariant', String(readerVariant))

    const fileIds = files.map((fileInfo) => fileInfo.fileId)
    formData.append('filesIds', JSON.stringify(fileIds))

    const response = await api.post(`/invoices/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function uploadInvoicesOpenAI({ invoices, reader }: UploadInvoicesOpenAI): Promise<UploadFile[]> {
  try {
    const data = new FormData()

    const ids = invoices?.map(invoice => invoice?.id)
    const pollingIds = invoices?.map(invoice => invoice?.pollingId)

    data.append('reader', reader)

    for (const id of ids) {
      data.append('ids', id!)
    }

    for (const pollingId of pollingIds) {
      data.append('pollingIds', pollingId!)
    }

    for (const invoice of invoices) {
      data.append('files', invoice.file, sanitizeFilename(invoice.file.name))
    }

    const response = await api.post(`/invoices/upload/openai`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response?.data?.files ?? []
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function checkStatusInvoicesOpenAI({ invoices }: UploadInvoicesOpenAI): Promise<UploadFile[]> {
  try {
    const promises = invoices.map(async (invoice) => {
      const response = await api.get<UploadFile>(`/invoices/upload/openai/${invoice.pollingId}`)

      return {
        ...invoice,
        ...response.data
      }
    })

    const response = await Promise.all(promises)

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function persistInvoicesOpenAI({ invoices, reader }: UploadInvoicesOpenAI): Promise<UploadFile[]> {
  try {
    const data = new FormData()
    data.append('reader', reader)

    for (const invoice of invoices) {
      const item = {
        ...invoice,
        file: null
      }

      data.append('items', JSON.stringify(item))
    }

    for (const invoice of invoices) {
      data.append('files', invoice.file, sanitizeFilename(invoice.file.name))
    }

    const promises = invoices.map(async (invoice) => {
      const response = await api.post<UploadFile>(`/invoices/upload/openai/${invoice.id}`, data)

      return response?.data
    })

    const response = await Promise.all(promises)
    return response.flat()
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function downloadBulletinCelesc() {
  try {
    return api.get('/dashboard/consumidor/boletim-medicao-celesc', { responseType: 'blob' })
  } catch (error) {
    console.log(error)
    throw error
  }
}

export async function downloadBulletinEnergisa() {
  try {
    return api.get('/dashboard/consumidor/boletim-medicao-energisa', { responseType: 'blob' })
  } catch (error) {
    console.log(error)
    throw error
  }
}

// export function dashboardAreaChart({
//   usinas_id,
//   energyType,
//   month,
//   year,
// }: IDashboardAreaChartProps) {
//   return api.post(
//     `/dashboard/gerador/area?mes_ref=${year}-${month < 10 ? '0' + month : month}-01`,
//     { usinas_id, periodo: 12, campo: energyType },
//   )
// }

export function dashboardEnergyDownloadExcel({
  usinas_id,
  month,
  year,
  periodo,
}: {
  usinas_id: number[] | undefined
  month: number
  year: number
  periodo: number
}) {
  let url = `/dashboard/gerador/excel?mes_ref=${year}-${
    month < 10 ? '0' + month : month
  }-01&periodo=${periodo}`

  usinas_id && (url += usinas_id.map((item) => `&usina_id=${item}`))

  url = url.replace(/,/g, '')
  return api.get(url, {
    responseType: 'blob',
  })
}

export function dashboardBalanceDownloadExcel({
  usinas_id,
  month,
  year,
  periodo,
  isConsumer,
}: {
  usinas_id: number[] | undefined
  month: number
  year: number
  periodo: number
  isConsumer?: boolean | undefined
}) {
  let url = `/dashboard/gerador/balanco/excel?mes_ref=${year}-${
    month < 10 ? '0' + month : month
  }-01&periodo=${periodo}`

  usinas_id && (url += usinas_id.map((item) => `&usina_id=${item}`))
  isConsumer && (url += `&is_consumer=${isConsumer}`)
  url = url.replace(/,/g, '')
  return api.get(url, {
    responseType: 'blob',
  })
}

interface IGetFaturamento extends IMonthFilterProps {
  name?: string
  page: number
  limit: number
  order?: string
  sort?: string
  filter?: Record<string, unknown>
}

export function getFaturamento({ year, month, name, page, limit, order, sort }: IGetFaturamento) {
  let url = `/super/faturamento?mes_ref=${year}-${
    month < 10 ? '0' + month : month
  }-01&page=${page}&limit=${limit}`

  if (name) {
    url += `&name=${name}`
  }

  if (order) {
    url += `&order=${order}`
  }

  if (sort) {
    url += `&sort=${sort}`
  }

  return api.get<IFaturamentoData>(url)
}

export function getAllInvoices({ year, month, name, page, limit, order, sort, filter }: IGetFaturamento) {
  let url = `/super/invoices?mes_ref=${year}-${
    month < 10 ? '0' + month : month
  }-01&page=${page}&limit=${limit}`

  if (name) {
    url += `&name=${name}`
  }

  if (order) {
    url += `&order=${order}`
  }

  if (sort) {
    url += `&sort=${sort}`
  }

  if(filter && Object.keys(filter).length > 0) {
    url += `&filter=${convertObjectToQueryParams(filter, ';')}`
  }

  return api.get<IInvoiceEditData>(url).then((res) => res.data)
}

export function deleteInvoice(id: number) {
  return api.delete<{ success: boolean }>(`faturas/${id}`).then((res) => res.data)
}

export function getFaturamentoSugestions() {
  return api.get<Array<string>>(`/super/faturamento/sugestions`)
}
export async function getMetodosDePagamento() {
  const paymentMethodsResponse = await api.get('/metodos-pagamento/consumer')
  return paymentMethodsResponse.data
}

export function getRegraRemuneracao() {
  return api.get('/ucs/regras-remuneracao')
}

export function consumersDownload({
  sort,
  order,
  mesRef,
  filter,
  fields
}: {
  sort?: string
  order?: string
  mesRef?: string
  filter?: string
  fields?: string[]
}) {
  const params = []
  if (sort) params.push(`sort=${sort}`)
  if (order) params.push(`order=${order}`)
  if (mesRef) params.push(`mes_ref=${mesRef}`)
  if (filter) params.push(`filter=${filter}`)
  if (fields) params.push(`fields=${fields.join(',')}`)

  const url = `/consumidores/csv${params.length > 0 ? '?' + params.join('&') : ''}`

  return api.get(url, { responseType: 'blob' })
}

export function ucsDownload({
  customersId = [],
  powerPlantId = [],
  mesRef,
  sort = 'nome',
  order = 'ASC',
  filter = '',
  fields = []
}: {
  sort?: string
  order?: string
  customersId: number[] | null
  powerPlantId: number[] | null
  mesRef?: string
  filter?: string
  fields: string[]
}) {
  const params = []
  if (mesRef) params.push(`mes_ref=${mesRef}`)
  if (sort) params.push(`sort=${sort}`)
  if (order) params.push(`order=${order}`)
  if (filter) params.push(`filter=${filter}`)

  const url = `/ucs/csv${params.length > 0 ? '?' + params.join('&') : ''}`

  return api.post(
    url,
    {
      customersId,
      powerPlantId,
      fields
    },
    { responseType: 'blob' },
  )
}

export function createManualPdf(data: FormData) {
  return api.post('/pagamentos/preview-cobranca/manual-pdf', data).then((res) => res.data)
}

export function createManual(data: any) {
  return api.post('/pagamentos/preview-cobranca/manual', data).then((res) => res.data)
}

export function changeManual(data: any) {
  return api.put('/pagamentos/preview-cobranca/manual', data).then((res) => res.data)
}

export function getClosure(data: any) {
  return api.post('/pagamentos/preview-cobranca/closure', data).then((res) => res.data.data)
}

export function deletePayment(id: number) {
  return api.delete(`/pagamentos/${id}/baixar`)
}

export async function getAzureData(url: string, hasMuc: boolean, managerId?: number, file?: File) {
  if (file) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('hasMuc', String(hasMuc))
    if (managerId) {
      formData.append('managerId', String(managerId))
    }

    const response = await api.post('/invoices/azure', formData)

    return response.data
  }

  const managerIdParam = managerId !== undefined ? `&managerId=${managerId}` : ''
  return api
    .get(`/faturas/azure?url=${url}&hasMuc=${hasMuc}${managerIdParam}`)
    .then((val) => val.data)
}

export async function getAwsData(url: string, file?: File) {
  if (file) {
    const formData = new FormData()
    formData.append('file', file)
    const response = await api.post('/invoices/aws', formData)
    return response.data
  }

  return api.get(`/faturas/aws?url=${url}`).then((val) => val.data)
}

export function sendIaData(data: any) {
  return api.post(`/faturas/ia`, data)
}

export function cemigDemonstrative(data: FormData) {
  return api.post('/faturas/demonstrative/cemig', data).then((val) => val.data)
}

export function sendFees(data: FormData) {
  return api.post('/fees', data)
}

export function invoiceToDemonstrative(data: { values: { uc: string; mes_referencia: string }[] }) {
  return api.post('/faturas/demonstrative', data).then((val) => val.data)
}

export function getAllClients() {
  return api.get('/super/clients-ids')
}

export function getDemonstratives({
  sort,
  filter,
  limit = 10,
  order = 'ASC',
  page = 1,
  year,
  clients,
  month,
  status,
  suppliers
}: {
  page?: number
  sort?: string
  order?: string
  year?: number
  month?: number
  filter?: string | null
  limit?: number
  clients?: string
  status?: string
  suppliers?: string
}) {
  console.log(`&clients=${clients}`)
  let url = `/faturas/demonstrativos?limit=${limit}&sort=${order}&page=${page}`

  if (sort) {
    url += `&order=${sort}`
  }
  if (filter) {
    url += `&filter=${filter}`
  }
  if (year) {
    url += `&ano=${year}`
  }
  if (month) {
    url += `&mes=${month}`
  }
  if (status) {
    url += `&status=${status}`
  }

  suppliers && (url  += suppliers.split(',').map((item) => `&suppliers=${item}`))
  clients && (url += clients.split(',').map((item) => `&clients=${item}`))

  url = url.replace(/,/g, '')
  return api.get(url)
}

export function getFees({
  sort,
  limit = 30,
  order = 'ASC',
  page = 1,
  distributors,
}: {
  page?: number
  sort?: string
  order?: string
  year?: number
  month?: number
  filter?: string | null
  limit?: number
  distributors?: string
}) {
  let url = `/fees?limit=${limit}&sort=${order}&page=${page}`
  if (sort) {
    url += `&order=${sort}`
  }

  distributors && (url += distributors.split(',').map((item) => `&distributors=${item}`))

  url = url.replace(/,/g, '')
  return api.get(url)
}

export function createUser(users: CreateUser) {
  return api.post('/super/create-user', users)
}

export function createConsumer(consumers: CreateConsumer) {
  return api.post('/super/create-consumer', consumers)
}
