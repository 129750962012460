import { ButtonsConsumerContainer, ConfigButtonsColumn, TableContainer } from './style'

import { AddOutlined, SettingsOutlined } from '@mui/icons-material'
import { Alert, Snackbar } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from 'react'
import { FloatingCheckboxes } from '../../../../components/FloatingCheckboxes'
import Loading from '../../../../components/Loading'
import ModalDelete from '../../../../components/Modal/ModalDelete'
import ModalSuccess from '../../../../components/Modal/ModalSuccess'
import { ModalRegisterConsumer } from '../../../../components/Modal/MyUnits/Registers/ModalRegisterConsumer'
import { HandleRegisterUpdateFormData } from '../../../../components/Modal/MyUnits/Registers/ModalRegisterConsumer/types'
import { SearchInput } from '../../../../components/SearchInput'
import { ActionTableButton } from '../../../../components/Table/ActionTableButton'
import { CheckboxType } from '../../../../interfaces/checkbox'
import {
  consumersDownload,
  deleteConsumer,
  myConsumers,
} from '../../../../services/requests/user-requests'
import { myConsumersType } from './types'
import { getConsumerColumns } from './utils'
import { DownloadDiv } from '../../../Credits/Allocation/styles'
import DownloadIcon from '../../../../assets/download-icon-white.svg'
import dateFormatForRequest from './../../../../utils/dateFormatForRequest'
import { useOutletContext } from 'react-router'
import { IMyUnitsPageProps } from '../types'
import moment from 'moment'
import { usePermissions } from '../../../../hooks/usePermissions'
import { PermissionType } from '../../../../interfaces/permissions'
import { TableV2 } from '../../../../components/TableV2'
import { TablePagination } from '../../../../components/TableV2/components/TablePagination'
import { Sort } from '../../../../interfaces/conference'
import { paymentMethodsService } from '../../../../services/payment-method'
import { useToast } from '../../../../hooks/useToast'

export function Consumer() {
  const { mes_ref } = useOutletContext<IMyUnitsPageProps>()
  const { checkPermission } = usePermissions()
  const { toast } = useToast()

  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState(1)
  const [totalCountConsumers, setTotalCountConsumers] = useState(0)
  const [consumers, setConsumers] = useState<myConsumersType[]>([])
  const [filteredConsumers, setFilteredConsumers] = useState<myConsumersType[]>()
  const [isResgisterOpen, setIsRegisterOpen] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [isSuccessOpen, setIsSuccessOpen] = useState(false)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedConsumer, setSelectedConsumer] = useState<HandleRegisterUpdateFormData>()
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [ready, setReady] = useState(false)
  const [sortValues, setSortValues] = useState<Sort>()
  const [pageItemsAmount, setPageItemsAmount] = useState(30)
  const [paymentMethods, setPaymentMethods] = useState<{ value: number; label: string }[]>()
  const [tableCols, setTableCols] = useState<GridColDef[]>([])

  function handleChangePage(page: number) {
    setPage(page)
  }

  useEffect(() => {
    if (search !== undefined) {
      const delayDebounce = setTimeout(() => {
        if (search.length >= 3 || search.length === 0) {
          setPage(1)
          setReady(!ready)
        }
      }, 1000)

      return () => clearTimeout(delayDebounce)
    }
  }, [search])

  const getData = useCallback(() => {
    setLoading(true)
    myConsumers({
      page,
      filter: search,
      limit: pageItemsAmount,
      order: sortValues?.order ?? 'ASC',
      sort: sortValues?.field ?? 'cliente_nome',
      customersId: [],
      powerPlantId: [],
      mes_ref: mes_ref ?? dateFormatForRequest(new Date()),
    })
      .then((response) => {
        setLoading(false)
        response.data?.meta?.total && setTotalCountConsumers(response.data.meta.total)
        response.data?.consumidores && setConsumers(response.data.consumidores)
        setFilteredConsumers(undefined)
      })
      .catch(() => {
        setErrorMessage('Erro ao carregar os dados de consumidores.')
        setLoading(false)
      })
  }, [mes_ref, page, pageItemsAmount, search, sortValues?.field, sortValues?.order])

  const fetchPaymentMethods = useCallback(async () => {
    try {
      const items = await paymentMethodsService.listPaymentMethods()
      setPaymentMethods(items.map((item) => ({ value: item.id, label: item.name })))
    } catch (error) {
      toast({
        type: 'error',
        message: 'Erro ao listar método de pagamento',
      })
    }
  }, [toast])

  useEffect(() => {
    fetchPaymentMethods()
  }, [fetchPaymentMethods])

  useEffect(() => {
    getData()
  }, [getData])

  const handleAdd = () => {
    setIsRegisterOpen(true)
  }

  const deleteFunction = async () => {
    try {
      setLoading(true)
      const response = await deleteConsumer(selectedIds)
      console.log('response', response.data)
      setIsSuccessOpen(true)
      getData()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleDownloadButton = async () => {
    await consumersDownload({
      sort: sortValues?.field,
      order: sortValues?.order,
      mesRef: mes_ref || dateFormatForRequest(new Date()),
      filter: search,
      fields: tableCols.filter((col) => !col.hide && col.field !== 'actions').map((col) => col.field),
    })
      .then((response) => {
        const blob = new Blob([response.data])
        const element = document.createElement('a')
        element.href = URL.createObjectURL(blob)
        element.download = `consumidores_${moment().format('DD_MM_YYYY')}.xlsx`

        document.body.appendChild(element)
        element.click()
      })
      .catch((err) => console.log(err))
  }

  const handleDelete = (ids: number[] | undefined = undefined) => {
    if (!ids && selectedIds.length < 1) return

    ids && setSelectedIds(ids)
    setIsDeleteOpen(true)
  }

  const handleEdit = (id: number) => {
    const consumer = consumers.find((item) => item.id === id)
    if (!consumer) return
    setSelectedConsumer(consumer)
    setIsEditOpen(true)
  }

  useEffect(() => {
    const data = filteredConsumers ?? consumers
    if (!data?.length) return

    const hasDeletePermission = checkPermission(PermissionType.MY_UNITS_CONSUMER_DELETE)
    const hasEditingPermission = checkPermission(PermissionType.MY_UNITS_CONSUMER_UPDATE)

    const columns = getConsumerColumns({
      data,
      deleteHandler: (id: number) => handleDelete([id]),
      editHandler: handleEdit,
      permissions: {
        hasDeletePermission,
        hasEditingPermission
      }
    })

    setTableCols((state) => {
      const getFromState = (name?: string) => state.find(item => item.headerName === name)

      const newColumns = columns.reduce((acc, item) => {
        const name = item.headerName
        const oldItem = getFromState(name)

        item.hide = oldItem?.hide ?? item?.hide
        acc.push(item)
        return acc
      }, [] as GridColDef[])

      return newColumns
    })
  }, [filteredConsumers, consumers])

  const handleSelectTableColumns = (checkboxes: CheckboxType[]) => {
    setTableCols((cols) =>
      cols.map((col) => ({
        ...col,
        hide: !checkboxes.find((box) => box.label === col.headerName)?.checked,
      })),
    )
  }

  const hasCreatingPermission = checkPermission(PermissionType.MY_UNITS_CONSUMER_CREATE)

  const rowsCurrentPage = filteredConsumers ?? consumers

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <TableContainer>
        <ButtonsConsumerContainer>
          <ConfigButtonsColumn gap={'1rem'}></ConfigButtonsColumn>
          <ConfigButtonsColumn gap='1rem'>
            <SearchInput value={search} setValue={setSearch} style={{ width: 180 }} />
            <ConfigButtonsColumn gap='0.5rem'>
              <DownloadDiv>
                <img src={`${DownloadIcon}`} alt='Download Button' onClick={handleDownloadButton} />
              </DownloadDiv>
              <FloatingCheckboxes
                pagination={{
                  selected: pageItemsAmount,
                  setSelected: setPageItemsAmount,
                  options: [15, 20, 30, 50, 100],
                }}
                isFilterActive={false}
                title='Configurar Tabela'
                options={tableCols
                  .map((col) => ({
                    label: col.headerName || '',
                    checked: !col.hide,
                    id: Math.random(),
                  }))}
                customActionComponent={
                  <ActionTableButton icon={<SettingsOutlined />}></ActionTableButton>
                }
                submitBtnText='Salvar'
                submitAction={handleSelectTableColumns}
              />
              {hasCreatingPermission &&
                <ActionTableButton onClick={handleAdd} icon={<AddOutlined />} styles={{ fontSize: '15px' }}>
                  Adicionar
                </ActionTableButton>
              }
            </ConfigButtonsColumn>
          </ConfigButtonsColumn>
        </ButtonsConsumerContainer>
        <TableV2
          columns={tableCols}
          rows={rowsCurrentPage}
          alignText='center'
          isSelectable={true}
          messageNoRow='Não há consumidores cadastrados.'
          setSelectedItems={setSelectedIds}
          serverSorting
          sort={sortValues}
          onSort={(sort) => {
            setSortValues(sort)
            setPage(1)
          }}
        >
          <TablePagination
            pageInfo={{
              totalCount: totalCountConsumers,
              currentPage: page,
              totalPages: Math.ceil(totalCountConsumers / pageItemsAmount),
              limit: pageItemsAmount
            }}
            currentPageItemCount={rowsCurrentPage?.length}
            onChange={handleChangePage}
          />
        </TableV2>
      </TableContainer>

      {isResgisterOpen && (
        <ModalRegisterConsumer
          openModal={isResgisterOpen}
          setModalOpen={setIsRegisterOpen}
          postAction={getData}
          paymentMethods={paymentMethods}
        />
      )}
      {isEditOpen && (
        <ModalRegisterConsumer
          openModal={isEditOpen}
          setModalOpen={setIsEditOpen}
          editValues={selectedConsumer}
          postAction={getData}
          paymentMethods={paymentMethods}
        />
      )}

      {isDeleteOpen && (
        <ModalDelete
          openModal={isDeleteOpen}
          setModalOpen={setIsDeleteOpen}
          buttonColor='darkGreen'
          text='Tem certeza que deseja excluir um consumidor?'
          action={deleteFunction}
        />
      )}
      {isSuccessOpen && (
        <ModalSuccess
          buttonText='Ok, obrigado'
          buttonColor='darkGreen'
          text='Consumidor excluído com sucesso!'
          openModal={isSuccessOpen}
          setModalOpen={setIsSuccessOpen}
        />
      )}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={5000}
        onClose={() => setErrorMessage(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          width: 400,
        }}
      >
        <Alert
          variant='filled'
          severity='error'
          onClose={() => setErrorMessage(undefined)}
          sx={{
            width: 400,
          }}
        >
          <p>{errorMessage}</p>
        </Alert>
      </Snackbar>
    </>
  )
}
